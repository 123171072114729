export const companyTypes = [
  { key: 0, value: '', text: 'Please select an option' },
  { key: 16, text: "S-Corp", value: 16 },
  { key: 17, text: "C-Corp", value: 17 },
  { key: 18, text: "LLC", value: 18 },
  { key: 22, text: "501c3", value: 22 },
  { key: 23, text: "501c4", value: 23 },
  { key: 24, text: "501c6", value: 24 },
  { key: 25, text: "501c7", value: 25 },
  { key: 26, text: "687r", value: 26 },
  { key: 29, text: "LLP", value: 29 },
  { key: 30, text: "Non-Profit-Other", value: 30 },
  { key: 31, text: "PC", value: 31 },
  { key: 78, text: "Partnership", value: 78 }
];

export const marketingTypes = [
  { key: 0, value: '', text: 'Please select an option' },
  { key: 19, text: "Outreach by a WorkSmart Systems representative", value: 19 },
  { key: 20, text: "Current WorkSmart Systems client", value: 20 },
  { key: 72, text: "CPA or other trusted business advisor", value: 72 },
  { key: 73, text: "WorkSmart Systems blog or article", value: 73 },
  { key: 74, text: "Other", value: 74 }
]

export const states = [
  { key: 0, value: '', text: 'Select a state' },
  { key: 1, value: 'AL', text: 'AL' },
  { key: 2, value: 'AK', text: 'AK' },
  { key: 3, value: 'AZ', text: 'AZ' },
  { key: 4, value: 'AR', text: 'AR' },
  { key: 5, value: 'CA', text: 'CA' },
  { key: 6, value: 'CO', text: 'CO' },
  { key: 7, value: 'CT', text: 'CT' },
  { key: 8, value: 'DE', text: 'DE' },
  { key: 9, value: 'FL', text: 'FL' },
  { key: 10, value: 'GA', text: 'GA' },
  { key: 11, value: 'HI', text: 'HI' },
  { key: 12, value: 'ID', text: 'ID' },
  { key: 13, value: 'IL', text: 'IL' },
  { key: 14, value: 'IN', text: 'IN' },
  { key: 15, value: 'IA', text: 'IA' },
  { key: 16, value: 'KS', text: 'KS' },
  { key: 17, value: 'KY', text: 'KY' },
  { key: 18, value: 'LA', text: 'LA' },
  { key: 19, value: 'ME', text: 'ME' },
  { key: 20, value: 'MD', text: 'MD' },
  { key: 21, value: 'MA', text: 'MA' },
  { key: 22, value: 'MI', text: 'MI' },
  { key: 23, value: 'MN', text: 'MN' },
  { key: 24, value: 'MS', text: 'MS' },
  { key: 25, value: 'MO', text: 'MO' },
  { key: 26, value: 'MT', text: 'MT' },
  { key: 27, value: 'NE', text: 'NE' },
  { key: 28, value: 'NV', text: 'NV' },
  { key: 29, value: 'NH', text: 'NH' },
  { key: 30, value: 'NJ', text: 'NJ' },
  { key: 31, value: 'NM', text: 'NM' },
  { key: 32, value: 'NY', text: 'NY' },
  { key: 33, value: 'NC', text: 'NC' },
  { key: 34, value: 'ND', text: 'ND' },
  { key: 35, value: 'OH', text: 'OH' },
  { key: 36, value: 'OK', text: 'OK' },
  { key: 37, value: 'OR', text: 'OR' },
  { key: 38, value: 'PA', text: 'PA' },
  { key: 39, value: 'RI', text: 'RI' },
  { key: 40, value: 'SC', text: 'SC' },
  { key: 41, value: 'SD', text: 'SD' },
  { key: 42, value: 'TN', text: 'TN' },
  { key: 43, value: 'TX', text: 'TX' },
  { key: 44, value: 'UT', text: 'UT' },
  { key: 45, value: 'VT', text: 'VT' },
  { key: 46, value: 'VA', text: 'VA' },
  { key: 47, value: 'WA', text: 'WA' },
  { key: 48, value: 'WV', text: 'WV' },
  { key: 49, value: 'WI', text: 'WI' },
  { key: 50, value: 'WY', text: 'WY' }
];
