import React from 'react';
import { Form, Popup } from 'semantic-ui-react';

const SignInPopup = props => {

  // props
  const { disabled, buttonHandler } = props;

  return (
    <Popup
      disabled={ !disabled }
      trigger={
        <Form.Button
          color="blue"
          style={{ padding: '1rem 2rem', textTransform: 'uppercase', opacity: disabled ? 0.45 : 1 }}
          onClick={ !disabled ? buttonHandler : null }
        >
          Continue to RFP system
        </Form.Button>
      }
    >
      <Popup.Content>
        <p>Please enter your name and a valid email address to sign in.</p>
      </Popup.Content>
    </Popup>
  );
}

export default SignInPopup;
