import React, { useContext } from 'react';
import { Form } from 'semantic-ui-react';
import { Context } from '../../Context';

const DocumentUpload =props => {

  const { uploadDocument } = useContext( Context );

  const { typeID } = props;

  const handleDocumentUpload = e => {
    const data = new FormData();
    data.append( 'file', e.target.files[0] )
    uploadDocument( data, typeID );
  }

  return (
    <Form.Button
      primary
      fluid
      style={{ padding: 0 }}
    >
      <label htmlFor={ `upload${ typeID }` } style={{ display: 'block', padding: '.78571429em 1.5em .78571429em', cursor: 'pointer' }}>
        <input
          hidden
          id={ `upload${ typeID }` }
          type="file"
          name="file"

          onChange={ handleDocumentUpload }
        />
        Upload a File
      </label>
    </Form.Button>
  );
}

export default DocumentUpload;
