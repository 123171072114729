import React from 'react';
import { Form, Table } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { states } from '../../Services/options';
import { maskCurrencyInput, unmaskCurrency, integerKeyDown, floatKeyDown } from '../../Services/inputMasks';
import RecordValidationPopup from '../Shared/RecordValidationPopup';

const WorkersCompTable = props => {

  const { formData, updateField, setFormData } = useForm({
    state: '',
    employeeCount: '',
    code: '',
    rate: '',
    monthlyWages: ''
  });

  const { addRecord, mapRecords } = props;

  const addButtonHandler = () => {
    addRecord({ ...formData, monthlyWages: unmaskCurrency( formData.monthlyWages ) });
    setFormData({
      state: '',
      employeeCount: '',
      code: '',
      rate: '',
      monthlyWages: ''
    });
  }

  const disabled = (
    formData.state === ''
    || formData.employeeCount === ''
    || formData.code === ''
    || formData.rate === ''
    || formData.monthlyWages === ''
  );

  return (
    <div className="workers-comp-table">
      <Form>
        <Table basic="very" textAlign="center">
          <Table.Body>
            <Table.Row style={{ marginBottom: '1rem' }}>
              <Table.Cell width={ 3 }>
                <Form.Select
                  name="state"
                  label="State"
                  fluid
                  search
                  clearable
                  placeholder=""
                  options={ states }
                  value={ formData.state }
                  onChange={ ( e, { value, name } ) => updateField({ target: { name, value } })  }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  type="number"
                  name="employeeCount"
                  label="# of Employees"
                  min={ 0 }
                  fluid
                  value={ formData.employeeCount }
                  onChange={ updateField }
                  onKeyDown={ integerKeyDown }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  name="code"
                  label="WC Code"
                  fluid
                  value={ formData.code.replace( 'v', '' ) }
                  onChange={ updateField }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  type="number"
                  name="rate"
                  label="WC Rate"
                  min={ 0 }
                  step={ 0.01 }
                  icon="percent"
                  fluid
                  value={ formData.rate }
                  onChange={ updateField }
                  onBlur={ ( e ) => updateField({ target: { name: e.target.name, value: e.target.value } })}
                  onKeyDown={ floatKeyDown }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  name="monthlyWages"
                  label="Monthly Wages"
                  fluid
                  icon="dollar sign"
                  iconPosition="left"
                  value={ formData.monthlyWages }
                  onChange={ updateField }
                  onBlur={ ( e ) => updateField({ target: { name: e.target.name, value: maskCurrencyInput( e.target.value ) } })}
                  onKeyDown={ floatKeyDown }
                />
              </Table.Cell>
              <Table.Cell width={ 1 }>
                <RecordValidationPopup
                  recordType="workers compensation"
                  disabled={ disabled }
                  addButtonHandler={ addButtonHandler }
                />
              </Table.Cell>
            </Table.Row>
            { mapRecords() }
          </Table.Body>
        </Table>
      </Form>
    </div>
  );
}

export default WorkersCompTable;
