import React, { useContext } from 'react';
import { Form, Header, Icon, Table } from 'semantic-ui-react';
import { Context } from '../../Context';
import { maskCurrency } from '../../Services/inputMasks';
import WorkersCompTable from './WorkersCompTable';
import DocumentUpload from '../Shared/DocumentUpload';

const WorkersComp = props => {

  const { formData, updateField, documents, mapDocuments, submissionInfo } = useContext( Context );

  const addRecord = ( record ) => {
    updateField({
      target: {
        name: 'workersComp',
        value: [
          ...formData.workersComp,
          { ...record }
        ]
      }
    });
  }

  const removeRecord = ( index ) => {
    const updatedArray = formData.workersComp.filter( ( record, i ) => i !== index );
    updateField({
      target: {
        name: 'workersComp',
        value: updatedArray
      }
    })
  }

  const mapRecords = () => {
    if ( Object.keys( formData.workersComp ).length > 0 ) {
      return formData.workersComp.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.state }</Table.Cell>
            <Table.Cell>{ record.employeeCount }</Table.Cell>
            <Table.Cell>{ record.code }</Table.Cell>
            <Table.Cell>{ record.rate }</Table.Cell>
            <Table.Cell>{ maskCurrency( record.monthlyWages ) }</Table.Cell>
            <Table.Cell style={{ display: submissionInfo.accounting.isComplete ? 'none' : 'initial' }}>
              <Form.Button
                negative
                icon
                onClick={ () => removeRecord( i ) }
              >
                <Icon name="trash" />
              </Form.Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 6 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <div className="workers-comp">
      <Header
        className="form-header"
        color="blue"
      >
        Workers Compensation
      </Header>
      <fieldset>
        { submissionInfo.accounting.isComplete
          ? <>
              <Table basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell># of Employees</Table.HeaderCell>
                    <Table.HeaderCell>WC Code</Table.HeaderCell>
                    <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                    <Table.HeaderCell>Monthly Wages</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { mapRecords() }
                </Table.Body>
              </Table>
              <br />
              <Icon name="file alternate outline" />
              <span style={{ fontWeight: '700' }}>Workers Compensation Policy:</span>
              &nbsp;
              <span> { documents.wcPolicy ? mapDocuments( documents.wcPolicy, true ) : 'No document uploaded' }</span>
            </>
          : <>
              <legend>
                Please click the [ Save ] button to add a record
              </legend>
              <WorkersCompTable
                addRecord={ addRecord }
                mapRecords={ mapRecords }
              />
              <br />
              <Form>
                <Form.Field>
                  <label style={{ textAlign: 'center' }}>Please attach a copy of the declaration pages of the current Workers Compensation policy</label>
                </Form.Field>
                <DocumentUpload typeID={ 68 } />
                  { documents.wcPolicy ? mapDocuments( documents.wcPolicy ) : null }
              </Form>
            </>
        }
      </fieldset>
    </div>
  );
}

export default WorkersComp;
