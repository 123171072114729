import React, { useContext } from 'react';
import { Button, Dropdown, Form, List, Message, Tab } from 'semantic-ui-react';
import { marketingTypes } from '../Services/options';
import { Context } from '../Context';

const Submit = () => {

  // context
  const { formData, updateField, saveProgress, submitSection, checkSubmissionStatus } = useContext( Context );

  // handlers
  const handleSubmit = async () => {
    await submitSection( 5 );
    saveProgress();
  }

  if ( !checkSubmissionStatus() ) {
    return (
      <Tab.Pane className="submit" style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Message>
          <Message.Header>All sections must be marked complete before final submission of the RFP.</Message.Header>
          <br />
          <Message.Content>
            <List>
              <List.Item>
                <List.Icon name={ checkSubmissionStatus( 1 ) ? 'check' : 'close' } />
                <List.Content>Company Info</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={ checkSubmissionStatus( 2 ) ? 'check' : 'close' } />
                <List.Content>Accounting Information</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={ checkSubmissionStatus( 3 ) ? 'check' : 'close' } />
                <List.Content>Employee Benefits</List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name={ checkSubmissionStatus( 4 ) ? 'check' : 'close' } />
                <List.Content>Company Contacts</List.Content>
              </List.Item>
            </List>
          </Message.Content>
        </Message>
      </Tab.Pane>
    );
  }

  return (
    <Tab.Pane className="submit style={{ paddingTop: '2rem', paddingBottom: '2rem' }}">
      <div className="container">
        <Form>
          <Form.Group>
            <Form.Field width={ 6 }>
              <label>How did you hear about WorkSmart Systems?</label>
              <Dropdown
                icon='dropdown'
                name="wsMarketing"
                selection
                clearable
                placeholder="Please select an option"
                options={ marketingTypes }
                value={ formData.wsMarketing || '' }
                onChange={ (e, { name, value } ) => updateField({ target: { name, value } }) }
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <br />
        <p>
          <span style={{ fontWeight: 700 }}>Note:</span> Once you hit the submit button you will not be able to make any further changes.
          <br />
          Please make sure all the information you have available is entered before you submit your RFP.
        </p>
        <br />
        <Button
          style={{ width: '800px' }}
          primary
          size="huge"
          content="Submit Request For Proposal"
          onClick={ handleSubmit }
        />
      </div>
    </Tab.Pane>
  );
}

export default Submit;
