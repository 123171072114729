import { useReducer } from 'react';
import validationReducer, { ADD, REMOVE, SET } from '../Reducers/validationReducer';

export const useValidation = ( initialState = {} ) => {
  const [ state, dispatch ] = useReducer( validationReducer, initialState );

  const addError = async ({ name, error }) => await ADD( dispatch )({ name, error });

  const removeError = async name => await REMOVE( dispatch )( name );

  const setErrors = async errors => await SET( dispatch )( errors );

  return { errors: state, addError, removeError, setErrors };
}
