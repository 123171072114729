import React from 'react';
import { Dropdown, Form, Table } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { maskCurrencyInput, unmaskCurrency, integerKeyDown, floatKeyDown } from '../../Services/inputMasks';
import RecordValidationPopup from '../Shared/RecordValidationPopup';

const PayCyclesTable = ( { addRecord, mapRecords, refs } ) => {
  const { formData, updateField, setFormData } = useForm({
    cycleCount: '',
    ftCount: '',
    ptCount: '',
    wages: ''
  });

  const addButtonHandler = () => {
    addRecord({ ...formData, wages: unmaskCurrency( formData.wages) });
    setFormData({
      cycleCount: '',
      ftCount: '',
      ptCount: '',
      wages: ''
    });
  }

  const disabled = (
    formData.cycleCount === ''
    || formData.ftCount === ''
    || formData.ptCount === ''
    || formData.wages === ''
  );

  return (
    <div className="pay-cycles-table">
      <Form>
        <Table basic="very" textAlign="center">
          <Table.Body>
            <Table.Row>
              <Table.Cell >
                <Form.Field>
                  <label>Payroll Cycles Per Year</label>
                  <Dropdown
                    ref={ refs.cycleCountInput }
                    name="cycleCount"
                    fluid
                    options={[
                      { text: '', value: 0 },
                      { text: '12 (monthly)', value: 12 },
                      { text: '24 (semi-monthly)', value: 24 },
                      { text: '26 (bi-weekly)', value: 26 },
                      { text: '52 (weekly)', value: 52 }
                    ]}
                    selection
                    value={ formData.cycleCount || 0 }
                    onChange={ ( e, { name, value } ) => { updateField({ target: { name, value } }); } }
                  />
                </Form.Field>
                {/* <Form.Input
                  type="number"
                  name="cycleCount"
                  label="Payroll Cycles Per Year"
                  fluid
                  min={ 0 }
                  value={ formData.cycleCount }
                  onChange={ ( e, { value, name } ) => updateField({ target: { name, value } })  }
                  onKeyDown={ integerKeyDown }
                /> */}
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  name="ftCount"
                  label="# of Full Time Employees"
                  fluid
                  min={ 0 }
                  value={ formData.ftCount }
                  onChange={ updateField }
                  onKeyDown={ integerKeyDown }
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  type="number"
                  name="ptCount"
                  label="# of Part Time Employees"
                  fluid
                  min={ 0 }
                  value={ formData.ptCount }
                  onChange={ updateField }
                  onKeyDown={ integerKeyDown }
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Input
                  name="wages"
                  label="Gross Wages Per Month"
                  fluid
                  icon="dollar sign"
                  iconPosition="left"
                  value={ formData.wages }
                  onChange={ updateField }
                  onBlur={ ( e ) => updateField({ target: { name: e.target.name, value: maskCurrencyInput( e.target.value ) } })}
                  onKeyDown={ floatKeyDown }
                />
              </Table.Cell>
              <Table.Cell>
                <RecordValidationPopup
                  recordType="pay cycle"
                  disabled={ disabled }
                  addButtonHandler={ addButtonHandler }
                />
              </Table.Cell>
            </Table.Row>
            { mapRecords() }
          </Table.Body>
        </Table>
      </Form>
    </div>
  );
}

export default PayCyclesTable;
