import { actionDeclarer } from '../Services/actionDeclarer';

export const { ADD, REMOVE, SET } = actionDeclarer;

const validationReducer = ( state = {}, action ) => {
  switch ( action.type ) {
    case ADD:
      return { ...state, [ action.payload.name ]: action.payload.error};
    case REMOVE:
      const { [ action.payload ]: deleted, ...newState } = state;
      return newState;
    case SET:
      return action.payload;
    default:
      return state;
  }
};

export default validationReducer;
