import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';

const LockPopup = props => {

  // props
  const { open, handleOpen, user, handleClick } = props;

  return (
    <Popup
      open={ open }
      onOpen={ handleOpen }
      on="click"
      trigger={
        <Icon name="lock" circular color="black" inverted style={{ marginLeft: '1rem' }} />
      }
    >
      <Popup.Header>
        Section Locked
      </Popup.Header>
      <Popup.Content>
        <p>This Section has been locked by:</p>
        <span>{ user.name }</span>
        <br />
        <span>{ user.email }</span>
        <br />
        <br />
        <p>click <span style={{ color: '#2185d0', cursor: 'pointer' }} onClick={ handleClick }>here</span> to override and unlock.</p>
        <p><span style={{ fontWeight: 700 }}>note:</span> changes made by this user may have not been saved. If in doubt, please contact the user before overriding.</p>
      </Popup.Content>
    </Popup>
  );
}

export default LockPopup;
