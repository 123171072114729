import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;
const DEFAULT_HEADERS = {
  api_key: API_KEY,
  Accept: 'application/json'
};

/**
 *  helper method to perform an api requests
 * @param method
 * @param path
 * @param params
 * @param data
 * @param headers
 * @returns {Promise<*>}
 *
 **/

export const apiRequest = async ({ method = 'GET', path, params = {}, data = {}, headers = {} }) => {
  try {
    const response = await axios({
      method,
      baseURL: API_URL,
      url: path,
      params,
      data,
      headers: { ...DEFAULT_HEADERS, ...headers }
    });
    return response;
  } catch (error) {
    return error;
  }
};
