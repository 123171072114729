/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { apiRequest } from '../Services/api';
import { useForm } from '../Hooks/useForm';
import { useValidation } from '../Hooks/useValidation';
import { validateFein, validateZip, validateEmail } from '../Services/validation';
import { maskCurrencyInput, unmaskCurrency } from '../Services/inputMasks';

export const Context = React.createContext();

const Provider = props => {

  // parse the query string
  // call getData with the sessionKey

  useEffect( () => {
    const queryString = window.location.search;
    const params = parseQueryString( queryString );
    getData( params.SessionKey );
  }, [] );

  const parseQueryString = ( queryString ) => {
    let params = {}, queries, param, query;
    queries = queryString
      .slice( queryString.indexOf( '?' ) + 1 )
      .split( '&' );
    for ( query of queries ) {
      param = query.split( '=' );
      params[ param[0] ] = param[1];
    }
    return params;
  };

  // formData and api calls

  const { formData, updateField, setFormData } = useForm();

  const getData = async ( sessionKey = formData.sessionKey ) => {
    const response = await apiRequest({ path: `/submission/${ sessionKey }` });
    setFormData({
      ...response.data,
      unemploymentClaims: maskCurrencyInput( response.data.unemploymentClaims ),
      basicLifeAmount: maskCurrencyInput( response.data.basicLifeAmount )
    });
    setDocuments( response.data.documents );
  }

  const saveProgress = async ( newData = {} ) => {
    let data = {
      ...formData,
      ...newData,
      unemploymentClaims: unmaskCurrency( formData.unemploymentClaims ),
      basicLifeAmount: unmaskCurrency( formData.basicLifeAmount )
    }
    await apiRequest({
      method: 'post',
      path: '/submission',
      data
    });
    getData();
  }

  // user

  const { formData: user, updateField: updateUser } = useForm();
  const [ signedIn, setSignedIn ] = useState( false );

  const signIn = () => {
    updateField({
      target: {
        name: 'user',
        value: user
      }
    });
    setSignedIn( true );
  }

  const [ activeIndex, setActiveIndex ] = useState();
  const [ section, setSection ] = useState();

  const lockSection = async ( sectionID = 1 ) => {
    setSection( sectionID < 5 ? sectionID : 0 );
    await apiRequest({
      method: 'post',
      path: `/submission/${ formData.sessionKey }/lock/${ sectionID }`,
      data: user
    });
    getData();
  }

  const checkLocks = sectionID => {
    if ( !formData.lockInfo ) return false;
    switch ( sectionID ) {
      case 1:
        if ( checkSubmissionStatus( 1 ) ) return false;
        return ( formData.lockInfo.general.name !== user.name || formData.lockInfo.general.email !== user.email ) ? formData.lockInfo.general.isLocked : false
      case 2:
        if ( checkSubmissionStatus( 2 ) ) return false;
        return ( formData.lockInfo.accounting.name !== user.name || formData.lockInfo.accounting.email !== user.email ) ? formData.lockInfo.accounting.isLocked : false
      case 3:
        if ( checkSubmissionStatus( 3 ) ) return false;
        return ( formData.lockInfo.benefits.name !== user.name || formData.lockInfo.benefits.email !== user.email ) ? formData.lockInfo.benefits.isLocked : false
      case 4:
        if ( checkSubmissionStatus( 4 ) ) return false;
        return ( formData.lockInfo.contacts.name !== user.name || formData.lockInfo.contacts.email !== user.email ) ? formData.lockInfo.contacts.isLocked : false
      default:
        return
    }
  }

  // documents

  const [ documents, setDocuments ] = useState({});

  const uploadDocument = async ( data, typeID ) => {
    setLoading( true );
    setLoadingMessage( 'Uploading document...' );
    await apiRequest({
      method: 'post',
      path: `/submission/${ formData.sessionKey }/document/upload`,
      params: {
        typeID
      },
      data
    });
    await getDocuments();
    setLoading( false );
    setLoadingMessage( '' );
  }

  const deleteDocument = async id => {
    await apiRequest({
      method: 'delete',
      path: `/submission/${ formData.sessionKey }/document/${ id }`,
    });
    await getDocuments();
  }

const mapDocuments = ( documents, complete ) => documents.map( doc => <p key={ doc.id } style={{ color: '#000' }}>{ doc.name }{ complete ? null : <>&nbsp;&nbsp;<Icon link name="close" onClick={ () => deleteDocument( doc.id ) } /></> }</p> );

  const getDocuments = async () => {
    const response = await apiRequest({ path: `/submission/${ formData.sessionKey }` });
    setDocuments( response.data.documents );
  }

  // validation

  const { errors, addError, removeError, setErrors } = useValidation();

  const [ validationErrors, setValidationErrors ] = useState({});

  const validate = () => {
    return Object.entries( validationErrors ).every( entry => {
      // destructure key and value
      let [ name, error ] = entry;

      if ( error.dependent && error.dependent.some( dependent => formData[ dependent[0] ] === dependent[1] ) ) {
        return true;
      }

      let validate = [];
      if ( error.validate ) {
        validate = error.validate.split(' ');
      };

      let fieldName;
      if ( validate.includes( 'cpa' ) ) {
        fieldName = formData.cpa[ name.replace( 'cpa', '' ) ];
      } else if ( validate.includes( 'broker' ) ) {
        fieldName = formData.broker[ name.replace( 'broker', '' ) ];
      } else {
        fieldName = formData[ name ];
      }

      let isValid;
      if ( validate.includes( 'fein' ) ) {
        isValid = validateFein( fieldName )
      } else if ( validate.includes( 'email' ) ) {
        isValid = validateEmail( fieldName );
      } else if ( validate.includes( 'zip' ) ) {
        isValid = validateZip( fieldName );
      } else if ( validate.includes( 'length' ) ) {
        isValid = fieldName.length
      } else {
        isValid = fieldName;
      };

      return isValid;
    });
  }

  const mapErrors = () => {
    return Object.entries( errors )
      .sort( ( a, b ) => {
        return ( a[1].order > b[1].order ) ? 1 : -1;
      })
      .map( ( entry, i ) => {
        // destructure key and value
        let [ name, error ] = entry;

        // if the error is dependant on other fields, check if those fields are true
        if ( error.dependent && error.dependent.some( dependent => formData[ dependent[0] ] === dependent[1] ) ) {
          removeError( name );
          return null;
        }

        let validate = [];
        if ( error.validate ) {
          validate = error.validate.split(' ');
        };
        console.log( validate );

        let fieldName;
        if ( validate.includes( 'cpa' ) ) {
          fieldName = formData.cpa[ name.replace( 'cpa', '' ) ];
        } else if ( validate.includes( 'broker' ) ) {
          fieldName = formData.broker[ name.replace( 'broker', '' ) ];
        } else {
          fieldName = formData[ name ];
        }

        let isValid;
        if ( validate.includes( 'fein' ) ) {
          isValid = !validateFein( fieldName )
        } else if ( validate.includes( 'email' ) ) {
          isValid = !validateEmail( fieldName );
        } else if ( validate.includes( 'zip' ) ) {
          isValid = !validateZip( fieldName );
        } else if ( validate.includes( 'length' ) ) {
          isValid = !fieldName.length
        } else {
          isValid = !fieldName;
        };

        // if condition is true, return as message item, if not, remove it
        if ( isValid ) {
          return (
            <Message.Item
              key={ i }
              onClick={ () => {
                error.type === 'dropdown' ? error.ref.current.ref.current.focus()
                : error.type === 'datePicker' ? error.ref.current.setFocus()
                : error.ref.current.focus()
              }}
            >
              <Icon name="exclamation circle" /> <span className="message">{ error.message }</span>
            </Message.Item>
          );
        } else {
          removeError( name );
          return null;
        }
    });
  }

  // submission

  const checkSubmissionStatus = sectionID => {
    if ( !formData.submissionInfo ) return false;
    switch ( sectionID ) {
      case 1:
        return formData.submissionInfo.general.isComplete;
      case 2:
        return formData.submissionInfo.accounting.isComplete;
      case 3:
        return formData.submissionInfo.benefits.isComplete;
      case 4:
        return formData.submissionInfo.contacts.isComplete;
      default:
        return formData.submissionInfo.general.isComplete && formData.submissionInfo.accounting.isComplete && formData.submissionInfo.benefits.isComplete && formData.submissionInfo.contacts.isComplete;
    }
  }

  const completeSection = async ( sectionID, complete ) => {
    setLoading( true );
    setLoadingMessage( 'Processing...' );
    await saveProgress();
    await apiRequest({
      method: 'post',
      path: `/submission/${ formData.sessionKey }/complete/${ sectionID }`,
      data: user,
      params: {
        complete
      }
    });
    await getData();
    setLoading( false );
    setLoadingMessage( '' );
  }

  const submitSection = sectionID => {
    switch ( sectionID ) {
      case 1:
        completeSection( 1, true );
        return;
      case 2:
        completeSection( 2, true );
        return;
      case 3:
        completeSection( 3, true );
        return;
      case 4:
        completeSection( 4, true );
        return;
      case 5:
        completeSection( 5, true );
        return;
      default:
        return;
    }
  }

  const editSection = sectionID => {
    switch ( sectionID ) {
      case 1:
        completeSection( 1, false );
        return;
      case 2:
        completeSection( 2, false );
        return;
      case 3:
        completeSection( 3, false );
        return;
      case 4:
        completeSection( 4, false );
        return;
      case 5:
        completeSection( 5, false );
        return;
      default:
        return;
    }
  }

  // modals

  const [ errorModal, setErrorModal ] = useState( false );
  const [ saveOrCompleteModal, setSaveOrCompleteModal ] = useState( false );
  const [ submissionReviewModal, setSubmissionReviewModal ] = useState( false );

  // Loader
  const [ loading, setLoading ] = useState( false );
  const [ loadingMessage, setLoadingMessage ] = useState( '' );


  return (
    <Context.Provider
      value={{
        // data
        formData,
        updateField,
        salesAgent: formData.salesAgent,
        lockInfo: formData.lockInfo,
        submissionInfo: formData.submissionInfo,
        saveProgress,
        // user
        user,
        updateUser,
        signedIn,
        signIn,
        section,
        setSection,
        activeIndex,
        setActiveIndex,
        lockSection,
        checkLocks,
        // documents
        documents,
        uploadDocument,
        deleteDocument,
        mapDocuments,        // validation
        validationErrors,
        setValidationErrors,
        validate,
        mapErrors,
        errors,
        addError,
        setErrors,
        // submission
        checkSubmissionStatus,
        submitSection,
        editSection,
        // modals
        errorModal,
        setErrorModal,
        saveOrCompleteModal,
        setSaveOrCompleteModal,
        submissionReviewModal,
        setSubmissionReviewModal,
        loading,
        setLoading,
        loadingMessage
      }}
    >
      { props.children }
      { console.log( formData ) }
    </Context.Provider>
  );
}

export default Provider;
