import React, { useContext } from 'react';
import { Header } from 'semantic-ui-react';
import { maskPhone } from '../Services/inputMasks';
import { Context } from '../Context';
import SubmissionReviewModal from './SubmissionReviewModal';

const SubmitConfirm = () => {

  // context
  const {
    formData,
    salesAgent,
    setSubmissionReviewModal
  } = useContext( Context );

  const submissionReviewHandler = () => {
    setSubmissionReviewModal( true );
  }

  return (
    <div className="submit-confirm">
      <Header
        className="form-header"
        color="blue"
      >
        Thank you for your submission!
      </Header>
      <p>
        Thank you for your interest in WorkSmart systems! Our staff will quickly work on getting you a proposal and will be in contact. We have provided a confirmation number for your records.
      </p>
      <p>
        { formData.sessionKey }
      </p>
      <br />
      <p>
        If you have any questions, please contact:
      </p>
      <p>
        <span style={{ fontWeight: '900' }}>{ salesAgent.name }</span>, <a href={ `mailto:${ salesAgent.email }` }>{ salesAgent.email }</a>, { maskPhone( salesAgent.phone ) }
      </p>
      <br />
      <span style={{ color: '#292562', cursor: 'pointer', textDecoration: 'underline' }} onClick={ submissionReviewHandler }>Click here to review your submission.</span>
      <SubmissionReviewModal />
    </div>
  )
}

export default SubmitConfirm;
