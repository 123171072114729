import React, { useContext } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { Context } from '../../Context';

const Spinner = () => {
  const { loading, loadingMessage } = useContext( Context );

  return (
    <Dimmer active={ loading } style={{ position: 'fixed' }}>
      <Loader size="large" >
        { loadingMessage }
      </Loader>
    </Dimmer>
  );
}

export default Spinner
