import React, { useContext, useEffect, useRef } from 'react';
import { Form, Input, Tab } from 'semantic-ui-react';
import { Context } from '../../Context';
import PrimaryContact from './PrimaryContact';
import CompanyProfile from './CompanyProfile';
import MainLocation from './MainLocation';
import SaveProgress from '../Shared/SaveProgress';
import EditSection from '../Shared/EditSection';

const CompanyInfo = () => {

  // context
  const {
    formData,
    updateField,
    addError,
    errors,
    validationErrors,
    setValidationErrors,
    submissionInfo
  } = useContext( Context );

  // input refs
  let companyNameInput = useRef();
  let contactFirstNameInput = useRef();
  let contactLastNameInput = useRef();
  let contactTitleInput = useRef();
  let contactEmailInput = useRef();
  let feinInput = useRef();
  let typeOfCorpInput = useRef();
  let dateOfCorpInput = useRef();
  let locationNameInput = useRef();
  let locationAddressInput = useRef();
  let locationCityInput = useRef();
  let locationStateInput = useRef();
  let locationZipInput = useRef();

  // validation errors
  useEffect( () => {
    setValidationErrors({
      companyName: {
        order: 1,
        message: 'Company Name is a required field.',
        ref: companyNameInput
      },
      contactFirstName: {
        order: 2,
        message: 'First Name is a required field.',
        ref: contactFirstNameInput
      },
      contactLastName: {
        order: 3,
        message: 'Last Name is a required field.',
        ref: contactLastNameInput
      },
      contactTitle: {
        order: 4,
        message: 'Title is a required field.',
        ref: contactTitleInput
      },
      contactEmail: {
        order: 5,
        validate: 'email',
        message: 'Email is a required field.',
        ref: contactEmailInput
      },
      fein: {
        order: 6,
        validate: 'fein',
        message: 'Please enter a valid, 9-digit FEIN.',
        ref: feinInput
      },
      typeOfCorp: {
        order: 7,
        type: 'dropdown',
        message: 'Type of Corporation is a required field.',
        ref: typeOfCorpInput
      },
      dateOfCorp: {
        order: 8,
        type: 'datePicker',
        message: 'Date of Corporation is a required field.',
        ref: dateOfCorpInput,
      },
      locationName: {
        order: 9,
        message: 'Main Location Name is a required field.',
        ref: locationNameInput
      },
      locationAddress: {
        order: 10,
        message: 'Main Location Address Line 1 is a required field.',
        ref: locationAddressInput
      },
      locationCity: {
        order: 11,
        message: 'Main Location City is a required field.',
        ref: locationCityInput
      },
      locationState: {
        order: 12,
        type: 'dropdown',
        message: 'Main Location State is a required field.',
        ref: locationStateInput
      },
      locationZip: {
        order: 13,
        validate: 'zip',
        message: 'Main Location Zip is a required field. Please enter a valid, 5-digit zip code.',
        ref: locationZipInput
      }
    });
  }, [] );

  return (
    <Tab.Pane className="company-info form-section">
      <div className="container">
        { !submissionInfo.general.isComplete
          ? <div className="section-actions">
              <SaveProgress />
            </div>
          : <EditSection sectionName={ 'Company Information' } />
        }
        <Form>
          <Form.Field
            width={ 9 }
            required
            error={ 'companyName' in errors }
          >
            <label>Legal Company Name</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              ref={ companyNameInput }
              maxLength={ 255 }
              name="companyName"
              value={ formData.companyName }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
            />
          </Form.Field>
        </Form>
        <br />
        <PrimaryContact
          refs={{
            contactFirstNameInput,
            contactLastNameInput,
            contactTitleInput,
            contactEmailInput,
          }}
        />
        <br />
        <CompanyProfile
          refs={{
            feinInput,
            typeOfCorpInput,
            dateOfCorpInput
          }}
        />
        <br />
        <MainLocation
          refs={{
            locationNameInput,
            locationAddressInput,
            locationCityInput,
            locationStateInput,
            locationZipInput
          }}
        />
      </div>
    </Tab.Pane>
  );
}

export default CompanyInfo;
