import React, { useContext } from 'react';
import { Dropdown, Form, Header, Input } from 'semantic-ui-react';
import { Context } from '../../Context';
import { states } from '../../Services/options';
import { integerKeyDown } from '../../Services/inputMasks';

const MainLocation = props => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    submissionInfo
  } = useContext( Context );

  // props
  const { refs } = props;

  return (
    <div className="main-location">
      <Header
        className="form-header"
        color="blue"
      >
        Main location
      </Header>
      <Form>
        <fieldset>
          <Form.Field
            required
            error={ 'locationName' in errors }
          >
            <label>Name</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              ref={ refs.locationNameInput }
              maxLength={ 225 }
              name="locationName"
              value={ formData.locationName || '' }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
            />
          </Form.Field>
          <Form.Field
            required
            error={ 'locationAddress' in errors }
          >
            <label>Address Line 1</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              ref={ refs.locationAddressInput }
              maxLength={ 125 }
              name="locationAddress"
              value={ formData.locationAddress || '' }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
            />
          </Form.Field>
          <Form.Field>
            <label>Address Line 2</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              maxLength={ 125 }
              name="locationAddress2"
              value={ formData.locationAddress2 || '' }
              onChange={ updateField }
            />
          </Form.Field>
          <Form.Group>
            <Form.Field
              width={ 8 }
              required
              error={ 'locationCity' in errors }
            >
              <label>City</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                ref={ refs.locationCityInput }
                maxLength={ 125 }
                name="locationCity"
                value={ formData.locationCity || '' }
                onChange={ updateField }
                onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              />
            </Form.Field>
            <Form.Field
              width={ 4 }
              required
              error={ 'locationState' in errors }
            >
              <label>State</label>
              <Dropdown
                disabled={ submissionInfo.general.isComplete }
                icon={ submissionInfo.general.isComplete ? null : 'dropdown' }
                ref={ refs.locationStateInput }
                name="locationState"
                selection
                fluid
                search
                upward
                clearable={ !submissionInfo.general.isComplete }
                placeholder="Please select a state"
                options={ states }
                value={ formData.locationState || '' }
                onChange={ (e, { name, value } ) => updateField({ target: { name, value } }) }
                onBlur={ ( e, { name } ) => addError({ name: name, error: validationErrors[ name ] }) }
              />
            </Form.Field>
            <Form.Field
              width={ 4 }
              required
              error={ 'locationZip' in errors }
            >
              <label>Zip</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                ref={ refs.locationZipInput }
                maxLength={ 5 }
                name="locationZip"
                value={ formData.locationZip || '' }
                onChange={ updateField }
                onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                onKeyDown={ integerKeyDown }
              />
            </Form.Field>
          </Form.Group>
        </fieldset>
      </Form>
    </div>
  );
}

export default MainLocation;
