import React, { useContext } from 'react';
import { Icon, Step } from 'semantic-ui-react';
import { Context } from '../../Context';
import SaveOrCompleteModal from './SaveOrCompleteModal';
import ErrorModal from './ErrorModal';

const SaveProgress = props => {

  const { setSaveOrCompleteModal } = useContext( Context );

  const handleErrors = () => {
    setSaveOrCompleteModal( true );
  }

  return (
    <div className="save-progress">
      <Step.Group style={{ border: 0 }}>
        <Step style={{ border: '1px dashed #ccc', boxShadow: 'none' }}>
          <Icon name='save' />
          <Step.Content>
            <Step.Title style={{ fontWeight: '300' }} >
                Click <span style={{ color: '#2185d0', cursor: 'pointer' }} onClick={ handleErrors }>here</span> to save your progress.
            </Step.Title>
            <Step.Description></Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      <SaveOrCompleteModal />
      <ErrorModal />
    </div>
  );
}

export default SaveProgress;
