import React, { useContext } from 'react';
import { Form, Header, Icon, Popup, Table } from 'semantic-ui-react';
import { Context } from '../../Context';
import WithholdingsTable from './WithholdingsTable';
import DocumentUpload from '../Shared/DocumentUpload';

const Withholdings = ( { refs } ) => {

  const { formData, updateField, documents, mapDocuments, submissionInfo } = useContext( Context );

  const addRecord = ( record ) => {
    updateField({
      target: {
        name: 'withholdings',
        value: [
          ...formData.withholdings,
          { ...record }
        ]
      }
    });
  }

  const removeRecord = ( index ) => {
    const updatedArray = formData.withholdings.filter( ( record, i ) => i !== index );
    updateField({
      target: {
        name: 'withholdings',
        value: updatedArray
      }
    })
  }

  const mapRecords = () => {
    if ( Object.keys( formData.withholdings ).length > 0 ) {
      return formData.withholdings.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.state }</Table.Cell>
            <Table.Cell>{ record.employeeCount }</Table.Cell>
            <Table.Cell>{ record.sutaNum }</Table.Cell>
            <Table.Cell>{ record.taxRate }</Table.Cell>
            <Table.Cell>{ record.whAccount }</Table.Cell>
            <Table.Cell style={{ display: submissionInfo.accounting.isComplete ? 'none' : 'initial' }}>
              <Form.Button
                negative
                icon
                onClick={ () => removeRecord( i ) }
              >
                <Icon name="trash" />
              </Form.Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 6 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <div className="withholdings">
      <Header
        className="form-header"
        color="blue"
      >
        State Unemployment & Withholding
      </Header>
      <fieldset>
        { submissionInfo.accounting.isComplete
          ? <>
              <Table basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell># of Emloyees</Table.HeaderCell>
                    <Table.HeaderCell>SUTA Account #</Table.HeaderCell>
                    <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                    <Table.HeaderCell>Withholding Account #</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { mapRecords() }
                </Table.Body>
              </Table>
              <br />
              <Icon name="file alternate outline" />
              <span style={{ fontWeight: '700' }}>Unemployment Tax Return:</span>
              &nbsp;
              <span>{ documents.taxReturn ? mapDocuments( documents.taxReturn, true ) : 'No document uploaded' }</span>
            </>
          : <>
              <legend>
                Please click the [ Save ] button to add a record
              </legend>
              <WithholdingsTable
                refs={ refs }
                addRecord={ addRecord }
                mapRecords={ mapRecords }
              />
              <br />
              <Form>
                <Form.Field>
                  <label style={{ textAlign: 'center' }}>Please attach a copy of the last quarterly Indiana unemployment tax return</label>
                </Form.Field>
                <DocumentUpload typeID={ 37 } />
                { documents.taxReturn ? mapDocuments( documents.taxReturn ) : null }
                { formData.withholdings.find( ( { state } ) => state === 'IN' )
                  ? <>
                    <Form.Field>
                      <label style={{ textAlign: 'center' }}>
                        Please attach a copy of the last Indiana DWD Merit Rate Notice or most recent year available&nbsp;&nbsp;
                        <Popup
                          trigger={ <Icon circular name='info' /> }
                          content='You may call the Indiana Department of Workforce Development at 800-891-6499 to obtain the most recent Merit Rate Notice.'
                          inverted
                          size='small'
                        />
                      </label>
                    </Form.Field>
                    <DocumentUpload typeID={ 171 } />
                    { documents.taxReturn ? mapDocuments( documents.dwdDocument ) : null }
                    </>
                  : null
                }
              </Form>
            </>
        }
      </fieldset>
    </div>
  );
}

export default Withholdings;
