import React from 'react';
import { Form, Popup } from 'semantic-ui-react';

const RecordValidationPopup = props => {

  // props
  const { recordType, disabled, addButtonHandler } = props;

  return (
    <Popup
      disabled={ !disabled }
      trigger={
        <Form.Button
          className="add-record-button"
          style={{ margin: '1.5rem 0 0', padding: '0.78571429em 1.25rem', opacity: disabled ? 0.45 : 1 }}
          primary
          icon
          onClick={ !disabled ? addButtonHandler : null }
        >
          Save
        </Form.Button>
      }
    >
      <Popup.Content>
        <p>All fields are required when adding a new { recordType } record!</p>
      </Popup.Content>
    </Popup>
  );
}

export default RecordValidationPopup;
