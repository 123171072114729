import React, { useContext } from 'react';
import { Message } from 'semantic-ui-react';
import { Context } from '../../Context';

const ValidationContainer = props => {

  const { errors, mapErrors } = useContext( Context );

  if ( Object.keys( errors ).length > 0 ) {
    return (
      <div className="validation-container">
        <Message negative>
          <Message.Header>
            We found { Object.keys( errors ).length } error(s) in your form entry. Please see below for details.
          </Message.Header>
          <Message.List style={{ listStyle: 'none' }}>
            { mapErrors() }
          </Message.List>
        </Message>
      </div>
    );
  }

  return null;
}

export default ValidationContainer;
