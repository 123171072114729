/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Form, Header, Input } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { states } from '../../Services/options';
import { maskPhone, unmaskPhone, integerKeyDown } from '../../Services/inputMasks';
import { Context } from '../../Context';

const CompanyContact = props => {

  const { contactType, contactTitle, refs } = props;

  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    submissionInfo
  } = useContext( Context );

  // local form data ***not from context
  const { formData: contact, updateField: updateContact, setFormData } = useForm( formData[ contactType ] );

  useEffect( () => {
    updateField({
      target: {
        name: contactType,
        value: {
          ...formData[ contactType ],
          ...contact
        }
      }
    });
  }, [ contact, contactType ] );

  const handleCheckbox = ( e, { name, checked } ) => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    })
    const value = formData[ `no${ contactType }` ] === 3 ? 2 : 3;
    updateField({ target: { name, value  } });
  };

  return (
    <div className="company-contact">
      <Header
        className="form-header"
        color="blue"
      >
        { contactTitle }
      </Header>
      <Form>
        <Form.Checkbox
          disabled={ submissionInfo.contacts.isComplete }
          label={ `Our organization doesn't have a ${ contactTitle }` }
          name={ `no${ contactType }` }
          checked={ formData[ `no${ contactType }` ] === 3 || false }
          onChange={ handleCheckbox }
          tabindex="0"
        />
        { formData[ `no${ contactType }` ] !== 3
          ? <fieldset>
              <Form.Group>
                <Form.Field
                  width={ 8 }
                  required
                  error={ `${ contactType }firstName` in errors }
                >
                  <label>First Name</label>
                  <Input
                    disabled={ submissionInfo.contacts.isComplete }
                    ref={ refs[ `${ contactType }FirstNameInput` ] }
                    maxLength={ 125 }
                    name="firstName"
                    value={ contact.firstName || '' }
                    onChange={ updateContact }
                    onBlur={ e => addError({ name: `${ contactType }firstName`, error: validationErrors[ `${ contactType }firstName` ] })}
                  />
                </Form.Field>
                <Form.Field
                  width={ 8 }
                  required
                  error={ `${ contactType }lastName` in errors }
                >
                  <label>Last Name</label>
                  <Input
                    disabled={ submissionInfo.contacts.isComplete }
                    ref={ refs[ `${ contactType }LastNameInput` ] }
                    maxLength={ 125 }
                    name="lastName"
                    value={ contact.lastName || '' }
                    onChange={ updateContact }
                    onBlur={ e => addError({ name: `${ contactType }lastName`, error: validationErrors[ `${ contactType }lastName` ] })}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  width={ 8 }
                  required
                  error={ `${ contactType }email` in errors }
                >
                  <label>Email</label>
                  <Input
                    disabled={ submissionInfo.contacts.isComplete }
                    ref={ refs[ `${ contactType }EmailInput` ] }
                    maxLength={ 125 }
                    name="email"
                    value={ contact.email || '' }
                    onChange={ updateContact }
                    onBlur={ e => addError({ name: `${ contactType }email`, error: validationErrors[ `${ contactType }email` ] })}
                  />
                </Form.Field>
                <Form.Input
                  disabled={ submissionInfo.contacts.isComplete }
                  maxLength={ 14 }
                  name="phone"
                  label="Phone"
                  width={ 8 }
                  value={ maskPhone( contact.phone ) || '' }
                  onChange={ ( e, { name, value } ) => updateContact({ target: { name, value: unmaskPhone( value ) } }) }
                  onKeyDown={ integerKeyDown }
                />
              </Form.Group>
              <Form.Input
                disabled={ submissionInfo.contacts.isComplete }
                maxLength={ 125 }
                name="address"
                label="Address Line 1"
                value={ contact.address || '' }
                onChange={ updateContact }
              />
              <Form.Input
                disabled={ submissionInfo.contacts.isComplete }
                maxLength={ 125 }
                name="address2"
                label="Address Line 2"
                value={ contact.address2 || '' }
                onChange={ updateContact }
              />
              <Form.Group>
                <Form.Input
                  disabled={ submissionInfo.contacts.isComplete }
                  maxLength={ 125 }
                  name="city"
                  label="City"
                  width={ 8 }
                  value={ contact.city || '' }
                  onChange={ updateContact }
                />
                <Form.Select
                  disabled={ submissionInfo.contacts.isComplete }
                  icon={ submissionInfo.contacts.isComplete ? null : 'dropdown' }
                  name="state"
                  label="State"
                  width={ 4 }
                  fluid
                  search
                  clearable={ !submissionInfo.general.isComplete }
                  placeholder="Please select a state"
                  options={ states }
                  value={ contact.state || '' }
                  onChange={ ( e, { name, value } ) => updateContact({ target: { name, value } }) }
                />
                <Form.Input
                  disabled={ submissionInfo.contacts.isComplete }
                  maxLength={ 5 }
                  name="zip"
                  label="Zip"
                  width={ 4 }
                  value={ contact.zip || '' }
                  onChange={ updateContact }
                  onKeyDown={ integerKeyDown }
                />
              </Form.Group>
            </fieldset>
          : null
        }
      </Form>
    </div>
  );
}

export default CompanyContact;
