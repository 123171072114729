export const validateFein = value => {
  return /^[0-9]{9}$/.test( value );
}

export const validateZip = value => {
  return /^[0-9]{5}$/.test( value );
}

export const validateEmail = value => {
  return /^.+@.+$/.test( value );
}
