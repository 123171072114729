import React, { useContext } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { Context } from '../../Context';
import DocumentUpload from '../Shared/DocumentUpload';

const VisionPlanDetails = props => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    documents,
    mapDocuments,
    submissionInfo
  } = useContext( Context );

  // props
  const { refs } = props;

  return (
    <div className="vision-plan-details">
      <fieldset>
        <Form.Field
          width={ 8 }
          required
          error={ 'visionRenewalDate' in errors }
        >
          <label>When is your vision plan renewal date?</label>
          <DatePicker
          type="date"
            disabled={ submissionInfo.benefits.isComplete }
            ref={ refs.visionRenewalDateInput }
            isClearable
            selected={ formData.visionRenewalDate ? new Date( formData.visionRenewalDate ) : null }
            customInput={
              <MaskedInput
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                keepCharPositions= {true}
                guide = {true}
              />
            }
            onChange={ e => { updateField({ target: { name: "visionRenewalDate", value: e } }) } }
            onBlur={ () => addError({ name: 'visionRenewalDate' , error: validationErrors[ 'visionRenewalDate' ] }) }
            onKeyDown={ e => {
              if ( e.key === "Tab" ) {
                refs.visionRenewalDateInput.current.setOpen( false );
              }
            }}
          />
        </Form.Field>
        <Form.Field
          width={ 8 }
          required
          error={ 'visionPlanVendor' in errors }
        >
          <label>Who is your vision plan vendor?</label>
          <Input
            disabled={ submissionInfo.benefits.isComplete }
            ref={ refs.visionPlanVendorInput }
            name="visionPlanVendor"
            value={ formData.visionPlanVendor || '' }
            onChange={ updateField }
            onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
          />
        </Form.Field>
        { submissionInfo.benefits.isComplete
          ? <>
              <br />
              <Icon name="file alternate outline" />
              <span style={{ fontWeight: '700' }}>Vision Plan Summary:</span>
              &nbsp;
              <span>{ documents.visionSummary ? mapDocuments( documents.visionSummary, true ) : 'No document uploaded' }</span>
              <br />
            </>
          : <>
              <br />
              <Form.Field>
                <label>Please attach your vision plan summary.</label>
              </Form.Field>
              <DocumentUpload typeID={ 46 } />
              { documents.visionSummary ? mapDocuments( documents.visionSummary ) : null }
            </>
        }
        { submissionInfo.benefits.isComplete
          ? <>
              <br />
              <Icon name="file alternate outline" />
              <span style={{ fontWeight: '700' }}>Vision Insurance Invoice:</span>
              &nbsp;
              <span>{ documents.visionInvoice ? mapDocuments( documents.visionInvoice, true ) : 'No document uploaded' }</span>
            </>
          : <>
              <br />
              <Form.Field>
                <label>Please attach your most recent vision insurance invoice.</label>
              </Form.Field>
              <DocumentUpload typeID={ 47 } />
              { documents.visionInvoice ? mapDocuments( documents.visionInvoice ) : null }
            </>
        }
      </fieldset>
    </div>
  );
}

export default VisionPlanDetails;
