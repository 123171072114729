import React, { useContext, useEffect, useRef } from 'react';
import { Form, Header, Input, Tab } from 'semantic-ui-react';
import { Context } from '../../Context';
import MedicalPlanDetails from './MedicalPlanDetails';
import DentalPlanDetails from './DentalPlanDetails';
import VisionPlanDetails from './VisionPlanDetails';
import SaveProgress from '../Shared/SaveProgress';
import EditSection from '../Shared/EditSection';

const EmployeeBenefits = () => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    setValidationErrors,
    submissionInfo
  } = useContext( Context );

  // input refs
  let medicalRenewalDateInput = useRef();
  let medicalPlanVendorInput = useRef();
  let plansOfferedInput = useRef();
  let basicLifeAmountInput = useRef();
  let cobraVendorInput = useRef();
  let cobraCountInput = useRef();
  let dentalRenewalDateInput = useRef();
  let dentalPlanVendorInput = useRef();
  let visionRenewalDateInput = useRef();
  let visionPlanVendorInput = useRef();

  // validation errors
  useEffect( () => {
    setValidationErrors({
      medicalRenewalDate: {
        order: 1,
        type: 'datePicker',
        dependent: [
          [ 'medicalSponsorsPlan', false ]
        ],
        message: 'Please enter your medical plan renewal date.',
        ref: medicalRenewalDateInput
      },
      medicalPlanVendor: {
        order: 2,
        dependent: [
          [ 'medicalSponsorsPlan', false ]
        ],
        condition: formData.medicalPlanVendor,
        message: 'Please enter your medical plan vendor.',
        ref: medicalPlanVendorInput
      },
      plansOffered: {
        order: 3,
        dependent: [
          [ 'medicalSponsorsPlan', false ]
        ],
        condition: formData.plansOffered,
        message: 'Please enter the number of plan type options offered by your medical plan.',
        ref: plansOfferedInput
      },
      basicLifeAmount: {
        order: 4,
        dependent: [
          [ 'medicalSponsorsPlan', false ],
          [ 'basicLifeOffered', false ]
        ],
        condition: formData.basicLifeAmount,
        message: 'Please enter the amount of basic life your insurance plan offers.',
        ref: basicLifeAmountInput
      },
      cobraVendor: {
        order: 5,
        dependent: [
          [ 'extendsCobra', false ]
        ],
        condition: formData.cobraVendor,
        message: 'Please enter your COBRA vendor.',
        ref: cobraVendorInput
      },
      cobraCount: {
        order: 6,
        dependent: [
          [ 'extendsCobra', false ]
        ],
        condition: formData.cobraCount,
        message: 'Please enter the current number of participants with COBRA coverage.',
        ref: cobraCountInput
      },
      dentalRenewalDate: {
        order: 7,
        type: 'datePicker',
        dependent: [
          [ 'dentalSponsorsPlan', false ]
        ],
        message: 'Please enter your dental plan renewal date.',
        ref: dentalRenewalDateInput
      },
      dentalPlanVendor: {
        order: 8,
        dependent: [
          [ 'dentalSponsorsPlan', false ]
        ],
        condition: formData.dentalPlanVendor,
        message: 'Please enter your dental plan vendor.',
        ref: dentalPlanVendorInput
      },
      visionRenewalDate: {
        order: 7,
        type: 'datePicker',
        dependent: [
          [ 'visionSponsorsPlan', false ]
        ],
        message: 'Please enter your vision plan renewal date.',
        ref: visionRenewalDateInput
      },
      visionPlanVendor: {
        order: 8,
        dependent: [
          [ 'visionSponsorsPlan', false ]
        ],
        condition: formData.visionPlanVendor,
        message: 'Please enter your vision plan vendor.',
        ref: visionPlanVendorInput
      }
    });
  }, [] );

  return (
    <Tab.Pane className="employee-benefits.isComplete form-section">
      <div className="container">
        { !submissionInfo.benefits.isComplete
          ? <div className="section-actions">
              <SaveProgress />
            </div>
          : <EditSection sectionName={ 'Employee Benefits' } />
        }
        <Form>
          <Header
            className="form-header"
            color="blue"
          >
            Medical
          </Header>
          <Form.Field required>
            <label>Does your organization sponsor a medical insurance plan?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="medicalSponsorsPlan"
              label="Yes"
              checked={ formData.medicalSponsorsPlan === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="medicalSponsorsPlan"
              label="No"
              checked={ formData.medicalSponsorsPlan === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.medicalSponsorsPlan
              ? <MedicalPlanDetails
                  refs={{
                    medicalRenewalDateInput,
                    medicalPlanVendorInput,
                    plansOfferedInput,
                    basicLifeAmountInput
                  }}
                />
              : null
          }
          <br />
          <Form.Field required>
            <label>Does your organization sponsor a section 125 cafeteria plan?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="section_125_offered"
              label="Yes"
              checked={ formData.section_125_offered === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="section_125_offered"
              label="No"
              checked={ formData.section_125_offered === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.section_125_offered
              ? <>
                  <br />
                  <Form.Field required>
                    <label>Does this include flexible spending accounts?</label>
                  </Form.Field>
                  <Form.Group inline>
                    <Form.Radio
                      disabled={ submissionInfo.benefits.isComplete }
                      name="flexibleSpendingAccount"
                      label="Yes"
                      checked={ formData.flexibleSpendingAccount === true }
                      onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
                      tabindex="0"
                    />
                    <Form.Radio
                      disabled={ submissionInfo.benefits.isComplete }
                      name="flexibleSpendingAccount"
                      label="No"
                      checked={ formData.flexibleSpendingAccount === false }
                      onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
                      tabindex="0"
                    />
                  </Form.Group>
                </>
              : null
          }
          <br />
          <Form.Field required>
            <label>Does your organization extend COBRA?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="extendsCobra"
              label="Yes"
              checked={ formData.extendsCobra === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="extendsCobra"
              label="No"
              checked={ formData.extendsCobra === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.extendsCobra
              ? <>
                  <br />
                  <>
                    <Form.Field
                      width={ 4 }
                      required
                      error={ 'cobraVendor' in errors }
                    >
                      <label>Who is your COBRA vendor?</label>
                      <Input
                        disabled={ submissionInfo.benefits.isComplete }
                        ref={ cobraVendorInput }
                        name="cobraVendor"
                        value={ formData.cobraVendor || '' }
                        onChange={ updateField }
                        onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                      />
                    </Form.Field>
                    <Form.Field
                      width={ 4 }
                      required
                      error={ 'cobraCount' in errors }
                    >
                      <label>Number of current participants:</label>
                      <Input
                        disabled={ submissionInfo.benefits.isComplete }
                        ref={ cobraCountInput }
                        type="number"
                        name="cobraCount"
                        width={ 4 }
                        value={ formData.cobraCount || '' }
                        onChange={ updateField }
                        onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                      />
                    </Form.Field>
                  </>
                </>
              : null
          }
          <Header
            className="form-header"
            color="blue"
          >
            Dental
          </Header>
          <Form.Field required>
            <label>Does your organization sponsor a dental insurance plan?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="dentalSponsorsPlan"
              label="Yes"
              checked={ formData.dentalSponsorsPlan === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="dentalSponsorsPlan"
              label="No"
              checked={ formData.dentalSponsorsPlan === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.dentalSponsorsPlan
              ? <DentalPlanDetails
                  refs={{
                    dentalRenewalDateInput,
                    dentalPlanVendorInput
                  }}
                />
              : null
          }

          <Header
            className="form-header"
            color="blue"
          >
            Vision
          </Header>
          <Form.Field required>
            <label>Does your organization sponsor a vision insurance plan?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="visionSponsorsPlan"
              label="Yes"
              checked={ formData.visionSponsorsPlan === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="visionSponsorsPlan"
              label="No"
              checked={ formData.visionSponsorsPlan === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.visionSponsorsPlan
              ? <VisionPlanDetails
                  refs={{
                    visionRenewalDateInput,
                    visionPlanVendorInput
                  }}
                />
              : null
          }

        </Form>
      </div>
    </Tab.Pane>
  );
}

export default EmployeeBenefits;
