import React, { useContext } from 'react';
import { Button, Form, Header, Modal, Table } from 'semantic-ui-react';
import { Context } from '../Context';
import { companyTypes, marketingTypes } from '../Services/options';
import { maskCurrency } from '../Services/inputMasks';

const SubmissionReviewModal = () => {

  const { formData, submissionReviewModal, setSubmissionReviewModal } = useContext( Context );

  const typeOfCorpValue = companyTypes.find( type => {
    return formData.typeOfCorp === type.value
  });

  const marketingValue = marketingTypes.find( type => {
    return formData.wsMarketing === type.value
  });

  const mapPayCycleRecords = () => {
    if ( Object.keys( formData.payCycles ).length > 0 ) {
      return formData.payCycles.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.cycleCount }</Table.Cell>
            <Table.Cell>{ record.ftCount }</Table.Cell>
            <Table.Cell>{ record.ptCount }</Table.Cell>
            <Table.Cell>{ maskCurrency( record.wages ) }</Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 6 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  };

  const mapWithholdingRecords = () => {
    if ( Object.keys( formData.withholdings ).length > 0 ) {
      return formData.withholdings.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.state }</Table.Cell>
            <Table.Cell>{ record.employeeCount }</Table.Cell>
            <Table.Cell>{ record.sutaNum }</Table.Cell>
            <Table.Cell>{ record.taxRate }</Table.Cell>
            <Table.Cell>{ record.whAccount }</Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 6 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  };

  const mapWorkersCompRecords = () => {
    if ( Object.keys( formData.workersComp ).length > 0 ) {
      return formData.workersComp.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.state }</Table.Cell>
            <Table.Cell>{ record.employeeCount }</Table.Cell>
            <Table.Cell>{ record.code }</Table.Cell>
            <Table.Cell>{ record.rate }</Table.Cell>
            <Table.Cell>{ maskCurrency( record.monthlyWages ) }</Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 6 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  };

  const submissionReviewHandler = () => {
    setSubmissionReviewModal( false );
  };

  return (
    <Modal open={ submissionReviewModal }>
      <Modal.Header>
        RFP Submission Review
      </Modal.Header>
      <Modal.Content scrolling>
        <Form className="form-review">

          {/* company Info */}
          <Form.Group>
            <Form.Input
              disabled
              label="Legal Company Name"
              value={ formData.companyName }
            />
          </Form.Group>

          {/* primary contact */}
          <Header
            className="form-header"
            color="blue"
          >
            Primary Contact
          </Header>
          <fieldset>
            <Form.Group>
              <Form.Input
                width={ 8 }
                disabled
                label="First Name"
                value={ formData.contactFirstName }
              />
              <Form.Input
                width={ 8 }
                disabled
                label="Last Name"
                value={ formData.contactLastName }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Title"
                value={ formData.contactTitle || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 8 }
                disabled
                label="Email"
                value={ formData.contactEmail }
              />
              <Form.Input
                width={ 8 }
                disabled
                label="Phone"
                value={ formData.contactPhone || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Address Line 1"
                value={ formData.contactAddress || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Address Line 2"
                value={ formData.contactAddress2 || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 8 }
                disabled
                label="City"
                value={ formData.contactCity || '' }
              />
              <Form.Input
                width={ 4 }
                disabled
                label="State"
                value={ formData.contactState || '' }
              />
              <Form.Input
                width={ 4 }
                disabled
                label="Zip"
                value={ formData.contactZip || '' }
              />
            </Form.Group>
          </fieldset>
          <br />

          {/* company profile */}
          <Form.Group>
            <Form.TextArea
              style={{ boxSizing: 'border-box' }}
              width={ 16 }
              disabled
              label="Description of Business"
              value={ formData.description || '' }
            />
          </Form.Group>
          <Form.Field required>
            <label>Is your organization domiciled in Indiana?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled
              label="Yes"
              checked={ formData.indianaDomiciled === true }
              tabindex="0"
            />
            <Form.Radio
              disabled
              label="No"
              checked={ formData.indianaDomiciled === false }
              tabindex="0"
            />
          </Form.Group>
          <Form.Group>
            <Form.Input
              width={ 4 }
              disabled
              label="Fein"
              value={ formData.fein }
            />
            <Form.Input
              width={ 4 }
              disabled
              label="Type of Corporation"
              value={ typeOfCorpValue.text }
            />
            <Form.Input
              width={ 4 }
              disabled
              label="Date of Incorporation"
              value={ new Date( formData.dateOfCorp ).toLocaleDateString() }
            />
          </Form.Group>
          <Form.Field>
            <label>Are your members or owners receiving W-2 wages?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled
              label="Yes"
              checked={ formData.w2wages === true }
              tabindex="0"
            />
            <Form.Radio
              disabled
              label="No"
              checked={ formData.w2wages === false }
              tabindex="0"
            />
          </Form.Group>

          {/* main location */}
          <Header
            className="form-header"
            color="blue"
          >
            Main Location
          </Header>
          <fieldset>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Name"
                value={ formData.locationName || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Address Line 1"
                value={ formData.locationAddress || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 16 }
                disabled
                label="Address Line 2"
                value={ formData.locationAddress2 || '' }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                width={ 8 }
                disabled
                label="City"
                value={ formData.locationCity || '' }
              />
              <Form.Input
                width={ 4 }
                disabled
                label="State"
                value={ formData.locationState || '' }
              />
              <Form.Input
                width={ 4 }
                disabled
                label="Zip"
                value={ formData.locationZip || '' }
              />
            </Form.Group>
          </fieldset>
          <br />

          {/* payroll */}
          <Form.Field>
            <label>Does your organization use a payroll service?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled
              label="Yes"
              checked={ formData.usesService === true }
              tabindex="0"
            />
            <Form.Radio
              disabled
              label="No"
              checked={ formData.usesService === false }
              tabindex="0"
            />
          </Form.Group>
          { formData.usesService
              ? <Form.Group>
                  <Form.Input
                    disabled
                    label="Payroll Vendor"
                    value={ formData.payRollVendor }
                  />
                </Form.Group>
              : null
          }

          {/* pay cycles */}
          <Header
            className="form-header"
            color="blue"
          >
            Pay Cycles
          </Header>
          <fieldset>
            <Table basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Payroll Cycles Per Year</Table.HeaderCell>
                    <Table.HeaderCell># of Full Time Employees</Table.HeaderCell>
                    <Table.HeaderCell># of Part Time Employees</Table.HeaderCell>
                    <Table.HeaderCell>Gross Wages Per Month</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { mapPayCycleRecords() }
                </Table.Body>
              </Table>
            </fieldset>
            <br />

            <Form.Field>
              <label>Does your organization pay 1099 independent contractors?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.contractors === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.contractors === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.contractors
            ? <>
                <Form.Group>
                  <Form.Input
                    disabled
                    label="How many contractors?"
                    value={ formData.contractorsCount || '' }
                  />
                </Form.Group>
                <Form.Field required>
                  <label>Does your organization pay 1099 contractors through a payroll service?</label>
                </Form.Field>
                <Form.Group inline>
                  <Form.Radio
                    disabled
                    label="Yes"
                    checked={ formData.usesContractorService === true }
                    tabindex="0"
                  />
                  <Form.Radio
                    disabled
                    label="No"
                    checked={ formData.usesContractorService === false }
                    tabindex="0"
                  />
                </Form.Group>
              </>
            : null
          }
            <Form.Field>
              <label>Does your organization use automated time and attendance in the form of time clocks or web-based applications?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.usesTimeClock === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.usesTimeClock === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.usesTimeClock
                ? <Form.Group>
                    <Form.Input
                      disabled
                      label="How many time clocks?"
                      value={ formData.timeClockCount }
                    />
                  </Form.Group>
                : null
            }
            <Form.Field>
              <label>Is your organization a Not for Profit?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.isNFP === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.isNFP === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.isNFP
                ? <>
                    <Form.Field>
                      <label>Is your organization subject to federal & State unemployment tax?</label>
                    </Form.Field>
                    <Form.Group inline>
                      <Form.Radio
                        disabled
                        label="Yes"
                        checked={ formData.isSubjectToFuta === true }
                        tabindex="0"
                      />
                      <Form.Radio
                        disabled
                        label="No"
                        checked={ formData.isSubjectToFuta === false }
                        tabindex="0"
                      />
                    </Form.Group>
                    { !formData.isSubjectToFuta
                        ? <Form.Group>
                            <Form.Input
                              disabled
                              label="Please enter the amount in unemployment claims your organization has paid out in the last 12 months"
                              value={ maskCurrency( formData.unemploymentClaims ) }
                            />
                          </Form.Group>
                        : null
                    }
                  </>
                : null
            }

            {/* withholding */}
            <Header
              className="form-header"
              color="blue"
            >
              State Unemployment & Withholding
            </Header>
            <fieldset>
              <Table basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell># of Emloyees</Table.HeaderCell>
                    <Table.HeaderCell>SUTA Account #</Table.HeaderCell>
                    <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                    <Table.HeaderCell>Withholding Account #</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { mapWithholdingRecords() }
                </Table.Body>
              </Table>
            </fieldset>
            <br />

            <Form.Field>
              <label>Are you current with all Unemployment Tax obligations in the state of Indiana?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.isTaxCurrent === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.isTaxCurrent === false }
                tabindex="0"
              />
            </Form.Group>

            {/* workers comp */}
            <Header
              className="form-header"
              color="blue"
            >
              Workers Compensation
            </Header>
            <fieldset>
              <Table basic="very" textAlign="center">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>State</Table.HeaderCell>
                    <Table.HeaderCell># of Employees</Table.HeaderCell>
                    <Table.HeaderCell>WC Code</Table.HeaderCell>
                    <Table.HeaderCell>Tax Rate</Table.HeaderCell>
                    <Table.HeaderCell>Monthly Wages</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  { mapWorkersCompRecords() }
                </Table.Body>
              </Table>
            </fieldset>
            <br />

            {/* employee benefits */}
            <Form.Field>
              <label>Does your oganization sponsor a medical insurance plan?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.medicalSponsorsPlan === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.medicalSponsorsPlan === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.medicalSponsorsPlan
                ? <>
                    <fieldset>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="When is your medical plan renewal date?"
                          value={ new Date( formData.medicalRenewalDate ).toLocaleDateString() }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="Who is your medical plan vendor?"
                          value={ formData.medicalPlanVendor }
                        />
                      </Form.Group>
                      <Form.Field>
                        <label>Your medical plan is:</label>
                      </Form.Field>
                      <Form.Group inline>
                        <Form.Radio
                          disabled
                          label="Self-Insured"
                          checked={ formData.selfInsured === true }
                          tabindex="0"
                        />
                        <Form.Radio
                          disabled
                          label="Fully-Insured"
                          checked={ formData.selfInsured === false }
                          tabindex="0"
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="How many types of medical plans are offered?"
                          value={ formData.plansOffered }
                        />
                      </Form.Group>
                      <Form.Field>
                        <label>What types of medical plan options are offered?</label>
                        <Form.Checkbox
                          disabled
                          label="POS"
                          checked={ formData.poS_Offered }
                          tabindex="0"
                        />
                        <Form.Checkbox
                          disabled
                          label="HMO"
                          checked={ formData.hmO_Offered }
                          tabindex="0"
                        />
                        <Form.Checkbox
                          disabled
                          label="Traditional PPO"
                          checked={ formData.ppO_Offered }
                          tabindex="0"
                        />
                        <Form.Checkbox
                          disabled
                          label="High Deductible Health Plan"
                          checked={ formData.hdhP_Offered }
                          tabindex="0"
                        />
                        <Form.Checkbox
                          disabled
                          label="Health Reimbursement Arrangement"
                          checked={ formData.hrA_Offered }
                          tabindex="0"
                        />
                      </Form.Field>
                    </fieldset>
                    <br />
                  </>
                : null
            }
            <Form.Field>
              <label>Does your plan include basic life?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.basicLifeOffered === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.basicLifeOffered === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.basicLifeOffered
                ? <Form.Group>
                    <Form.Input
                      disabled
                      label="How much of basic life does your plan offer?"
                      value={ maskCurrency( formData.basicLifeAmount ) }
                    />
                  </Form.Group>
                : null
            }
            <Form.Field>
              <label>Does your oganization sponsor a section 125 cafeteria plan?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.section_125_offered === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.section_125_offered === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.section_125_offered
                ? <>
                    <Form.Field>
                      <label>Does this include flexible spending accounts?</label>
                    </Form.Field>
                    <Form.Group inline>
                      <Form.Radio
                        disabled
                        label="Yes"
                        checked={ formData.flexibleSpendingAccount === true }
                        tabindex="0"
                      />
                      <Form.Radio
                        disabled
                        label="No"
                        checked={ formData.flexibleSpendingAccount === false }
                        tabindex="0"
                      />
                    </Form.Group>
                  </>
                : null
            }
            <Form.Field>
              <label>Does your oganization extend COBRA?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.extendsCobra === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.extendsCobra === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.extendsCobra
                ? <>
                    <Form.Group>
                      <Form.Input
                        disabled
                        label="Who is your COBRA vendor?"
                        value={ formData.cobraVendor }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        disabled
                        label="Number of current participants:"
                        value={ formData.cobraCount }
                      />
                    </Form.Group>
                  </>
                : null
            }
            <Form.Field>
              <label>Does your oganization sponsor a dental insurance plan?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.dentalSponsorsPlan === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.dentalSponsorsPlan === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.dentalSponsorsPlan
                ? <>
                    <fieldset>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="When is your dental plan renewal date?"
                          value={ new Date( formData.dentalRenewalDate ).toLocaleDateString() }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="Who is your dental plan vendor?"
                          value={ formData.dentalPlanVendor }
                        />
                      </Form.Group>
                    </fieldset>
                    <br />
                  </>
                : null
            }

            <Form.Field>
              <label>Does your oganization sponsor a vision insurance plan?</label>
            </Form.Field>
            <Form.Group inline>
              <Form.Radio
                disabled
                label="Yes"
                checked={ formData.visionSponsorsPlan === true }
                tabindex="0"
              />
              <Form.Radio
                disabled
                label="No"
                checked={ formData.visionSponsorsPlan === false }
                tabindex="0"
              />
            </Form.Group>
            { formData.visionSponsorsPlan
                ? <>
                    <fieldset>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="When is your vision plan renewal date?"
                          value={ new Date( formData.visionRenewalDate ).toLocaleDateString() }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input
                          disabled
                          label="Who is your vision plan vendor?"
                          value={ formData.visionPlanVendor }
                        />
                      </Form.Group>
                    </fieldset>
                    <br />
                  </>
                : null
            }

            {/* company contacts */}

            {/* cpa */}
            <Header
              className="form-header"
              color="blue"
            >
              Certified Public Accountant
            </Header>
            <Form.Checkbox
              disabled
              label="Our organization doesn't have a certified public accountant"
              checked={ formData.nocpa === 3 || false }
              tabindex="0"
            />
            { formData.nocpa < 3
              ? <>
                  <fieldset>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="FirstName"
                        value={ formData.cpa.firstName || '' }
                      />
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="LastName"
                        value={ formData.cpa.lastName || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Email"
                        value={ formData.cpa.email || '' }
                      />
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Phone"
                        value={ formData.cpa.phone || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Address Line 1"
                        value={ formData.cpa.address || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Address Line 2"
                        value={ formData.cpa.address2 || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 8 }
                        disabled
                        label="City"
                        value={ formData.cpa.city || '' }
                      />
                      <Form.Input
                        width={ 4 }
                        disabled
                        label="State"
                        value={ formData.cpa.state || '' }
                      />
                      <Form.Input
                        width={ 4 }
                        disabled
                        label="Zip"
                        value={ formData.cpa.zip || '' }
                      />
                    </Form.Group>
                  </fieldset>
                  <br />
                </>
              : null
            }

            {/* broker */}
            <Header
              className="form-header"
              color="blue"
            >
              Certified Public Accountant
            </Header>
            <Form.Checkbox
              disabled
              label="Our organization doesn't have a medical insurance broker"
              checked={ formData.nobroker === 3 || false }
              tabindex="0"
            />
            { formData.nobroker < 3
              ? <>
                  <fieldset>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="FirstName"
                        value={ formData.broker.firstName || '' }
                      />
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="LastName"
                        value={ formData.broker.lastName || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Email"
                        value={ formData.broker.email || '' }
                      />
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Phone"
                        value={ formData.broker.phone || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Address Line 1"
                        value={ formData.broker.address || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 16 }
                        disabled
                        label="Address Line 2"
                        value={ formData.broker.address2 || '' }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        width={ 8 }
                        disabled
                        label="City"
                        value={ formData.broker.city || '' }
                      />
                      <Form.Input
                        width={ 4 }
                        disabled
                        label="State"
                        value={ formData.broker.state || '' }
                      />
                      <Form.Input
                        width={ 4 }
                        disabled
                        label="Zip"
                        value={ formData.broker.zip || '' }
                      />
                    </Form.Group>
                  </fieldset>
                  <br />
                </>
              : null
            }
            <br />
            <Form.Input
              width={ 8 }
              disabled
              label="How did you hear about WorkSmart Systems?"
              value={ marketingValue ? marketingValue.text : '' }
            />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="close"
          onClick={ submissionReviewHandler }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default SubmissionReviewModal;
