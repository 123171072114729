import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Icon, Menu, Tab } from 'semantic-ui-react';
import { Context } from './Context';
import SignInForm from './Components/SignInForm';
import SubmitConfirm from './Components/SubmitConfirm';
import CompanyInfo from './Components/CompanyInfo/CompanyInfo';
import Payroll from './Components/Payroll/Payroll';
import EmployeeBenefits from './Components/EmployeeBenefits/EmployeeBenefits';
import CompanyContacts from './Components/CompanyContacts/CompanyContacts';
import Submit from './Components/Submit';
import LockPopup from './Components/Shared/LockPopup';
import ValidationContainer from './Components/Shared/ValidationContainer';
import Spinner from './Components/Shared/Spinner';
import "react-datepicker/dist/react-datepicker.css";
import './App.css';

const App = () => {

  // context
  const {
    signedIn,
    saveProgress,
    salesAgent,
    lockSection,
    lockInfo,
    activeIndex,
    setActiveIndex,
    checkSubmissionStatus,
    checkLocks,
    setValidationErrors,
    setErrors,
    setSection,
    formData
  } = useContext( Context );

  const [ lockPopup1, setLockPopup1 ] = useState( false );
  const [ lockPopup2, setLockPopup2 ] = useState( false );
  const [ lockPopup3, setLockPopup3 ] = useState( false );
  const [ lockPopup4, setLockPopup4 ] = useState( false );

  const handleLockPopups = useCallback(() => {
    if ( lockPopup1 ) setLockPopup1( false );
    if ( lockPopup2 ) setLockPopup2( false );
    if ( lockPopup3 ) setLockPopup3( false );
    if ( lockPopup4 ) setLockPopup4( false );
  }, [lockPopup1, lockPopup2, lockPopup3, lockPopup4])

  useEffect( () => {
    window.addEventListener( 'click', handleLockPopups );
    
    return () =>  window.removeEventListener( 'click', handleLockPopups );
  }, [handleLockPopups] );

  const panes = [
    {
      menuItem: (
        <Menu.Item style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, minWidth: '200px' }} key="company-information" onClick={ e => { e.stopPropagation(); handleLockPopups(); setLockPopup1( true ); } }>
          <Menu.Header>
            <span className="menu-header">Company Information</span>
            <br />
            <span className="menu-sub-header">General Company Information</span>
          </Menu.Header>
           {  checkSubmissionStatus( 1 )
                ? <Icon name="check" color={ activeIndex === 0 ? null : 'black' } style={{ marginLeft: '1rem', fontSize: '2rem' }}/>
                : checkLocks( 1 )
                  ? <LockPopup
                      open={ lockPopup1 }
                      handleOpen={ () => setLockPopup1( true ) }
                      user={ lockInfo.general }
                      handleClick={ () => overrideLock( 0 ) }
                    />
                  : null
          }
        </Menu.Item>
      ),
      render: () => (
        <CompanyInfo />
      )
    },
    {
      menuItem: (
        <Menu.Item style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, minWidth: '200px' }} key="accounting-information" onClick={ e => { e.stopPropagation(); handleLockPopups(); setLockPopup2( true ); } }>
          <Menu.Header>
            <span className="menu-header">Accounting Information</span>
            <br />
            <span className="menu-sub-header">Payroll, Withholding, Worker's Comp</span>
          </Menu.Header>
           {  checkSubmissionStatus( 2 )
                ? <Icon name="check" color={ activeIndex === 1 ? null : 'black' } style={{ marginLeft: '1rem', fontSize: '2rem' }} />
                : checkLocks( 2 )
                  ? <LockPopup
                      open={ lockPopup2 }
                      handleOpen={ () => setLockPopup2( true ) }
                      user={ lockInfo.accounting }
                      handleClick={ () => overrideLock( 1 ) }
                    />
                  : null
          }
        </Menu.Item>
      ),
      render: () => (
        <Payroll />
      )
    },
    {
      menuItem: (
        <Menu.Item style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, minWidth: '200px' }} key="employee-benefits" onClick={ e => { e.stopPropagation(); handleLockPopups(); setLockPopup3( true ); } }>
          <Menu.Header>
            <span className="menu-header">Employee Benefits</span>
            <br />
            <span className="menu-sub-header">Medical & Dental Insurance</span>
          </Menu.Header>
           {  checkSubmissionStatus( 3 )
                ? <Icon name="check" color={ activeIndex === 2 ? null : 'black' } style={{ marginLeft: '1rem', fontSize: '2rem' }} />
                : checkLocks( 3 )
                  ? <LockPopup
                      open={ lockPopup3 }
                      handleOpen={ () => setLockPopup3( true ) }
                      user={ lockInfo.benefits }
                      handleClick={ () => overrideLock( 2 ) }
                    />
                  : null
          }
        </Menu.Item>
      ),
      render: () => (
        <EmployeeBenefits />
      )
    },
    {
      menuItem: (
        <Menu.Item style={{ display: 'flex', flexGrow: 1, minWidth: '200px' }} key="company-contacts" onClick={ e => { e.stopPropagation(); handleLockPopups(); setLockPopup4( true ); } }>
          <Menu.Header>
            <span className="menu-header">Company Contacts</span>
            <br />
            <span className="menu-sub-header">Professional Contact Info</span>
          </Menu.Header>
           {  checkSubmissionStatus( 4 )
                ? <Icon name="check" color={ activeIndex === 3 ? null : 'black' } style={{ marginLeft: '1rem', fontSize: '2rem' }} />
                : checkLocks( 4 )
                  ? <LockPopup
                      open={ lockPopup4 }
                      handleOpen={ () => setLockPopup4( true ) }
                      user={ lockInfo.contacts }
                      handleClick={ () => overrideLock( 3 ) }
                    />
                  : null
          }
        </Menu.Item>
      ),
      render: () => (
        <CompanyContacts />
      )
    },
    {
      menuItem: (
        <Menu.Item
          style={{ display: 'flex', flexGrow: 1, minWidth: '200px' }}
          key="submit"
          className="submit-tab"
        >
          <Menu.Header>
            <span className="menu-header">Submit RFP</span>
            <br />
            <span className="menu-sub-header">Submit rfp for review</span>
          </Menu.Header>
        </Menu.Item>
      ),
      render: () => (
        <Submit />
      )
    },
  ]

  // handlers
  const handleTabChange = ( e, data ) => {
    const sectionID = data.activeIndex + 1;
    if ( checkSubmissionStatus( sectionID ) ) {
      setActiveIndex( data.activeIndex );
      setSection( sectionID );
    } else if ( !checkLocks( sectionID ) ) {
      saveProgress();
      setActiveIndex( data.activeIndex );
      lockSection( sectionID );
      setValidationErrors({});
      setErrors({});
    } else {
      e.preventDefault();
    }
  }

  const overrideLock = ( sectionIndex ) => {
    const sectionID = sectionIndex + 1;
    saveProgress();
    setActiveIndex( sectionIndex );
    lockSection( sectionID );
    setValidationErrors({});
    setErrors({});
  }

  if ( !salesAgent ) {
    return <Spinner />;
  }

  if ( !signedIn ) {
    return (
      <div className="app">
        { formData.submissionInfo.rfp.isComplete
          ? <SubmitConfirm />
          : <SignInForm />
        }
      </div>
    );
  }

  if ( formData.submissionInfo.rfp.isComplete ) {
    return (
      <div className="app">
        <SubmitConfirm />
      </div>
    );
  }

  return (
    <div className="app">
      <Tab
        activeIndex= { activeIndex }
        menu={{ color: 'blue', inverted: true, tabular: false, attached: true, style: { display: 'flex', overflow: 'hidden' } }}
        panes={ panes }
        onTabChange={ handleTabChange }
      />
      <ValidationContainer />
      <Spinner />
    </div>
  );
}

export default App;
