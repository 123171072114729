/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { Tab } from 'semantic-ui-react';
import { Context } from '../../Context';
import CompanyContact from './CompanyContact';
import SaveProgress from '../Shared/SaveProgress';
import EditSection from '../Shared/EditSection';

const CompanyContacts = () => {

  // context
  const { submissionInfo, setValidationErrors } = useContext( Context );

  const cpaFirstNameInput = useRef();
  const cpaLastNameInput = useRef();
  const cpaEmailInput = useRef();
  const brokerFirstNameInput = useRef();
  const brokerLastNameInput = useRef();
  const brokerEmailInput = useRef();

  useEffect( () => {
    setValidationErrors({
      cpafirstName: {
        order: 1,
        dependent: [
          [ `nocpa`, 3 ]
        ],
        validate: 'cpa',
        message: "Please enter a First Name for your organization's Certified Public Accountant",
        ref: cpaFirstNameInput
      },
      cpalastName: {
        order: 2,
        dependent: [
          [ `nocpa`, 3 ]
        ],
        validate: 'cpa',
        message: "Please enter a Last Name for your organization's Certified Public Accountant",
        ref: cpaLastNameInput
      },
      cpaemail: {
        order: 3,
        dependent: [
          [ `nocpa`, 3 ]
        ],
        validate: 'cpa email',
        message: "Please enter an Email Address for your organization's Certified Public Accountant",
        ref: cpaEmailInput
      },
      brokerfirstName: {
        order: 4,
        dependent: [
          [ `nobroker`, 3 ]
        ],
        validate: 'broker',
        message: "Please enter a First Name for your organization's Medical Insurance Broker",
        ref: brokerFirstNameInput
      },
      brokerlastName: {
        order: 5,
        dependent: [
          [ `nobroker`, 3 ]
        ],
        validate: 'broker',
        message: "Please enter a Last Name for your organization's Certified Public Accountant",
        ref: brokerLastNameInput
      },
      brokeremail: {
        order: 6,
        dependent: [
          [ `nobroker`, 3 ]
        ],
        validate: 'broker email',
        message: "Please enter an Email Address for your organization's Certified Public Accountant",
        ref: brokerEmailInput
      }
    });
  }, [] );

  return (
    <Tab.Pane className="company-contacts form-section">
      <div className="container">
        { !submissionInfo.contacts.isComplete
          ? <div className="section-actions">
              <SaveProgress />
            </div>
          : <EditSection sectionName={ 'Company Contacts' } />
        }
        <CompanyContact
          contactType="cpa"
          contactTitle="Certified Public Accountant"
          refs={{
            cpaFirstNameInput,
            cpaLastNameInput,
            cpaEmailInput
          }}
        />
        <br />
        <CompanyContact
          contactType="broker"
          contactTitle="Medical Insurance Broker"
          refs={{
            brokerFirstNameInput,
            brokerLastNameInput,
            brokerEmailInput
          }}
        />
      </div>
    </Tab.Pane>
  );
}

export default CompanyContacts;
