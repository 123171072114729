import React, { useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Context } from '../../Context';

const SaveOrCompleteModal = () => {

  // context
  const {
    saveProgress,
    validate,
    submitSection,
    section,
    setErrors,
    validationErrors,
    setErrorModal,
    saveOrCompleteModal,
    setSaveOrCompleteModal
  } = useContext( Context );

  // handlers
  const handleSave = () => {
    saveProgress();
    setSaveOrCompleteModal( false );
  }

  const handleComplete = () => {
    if ( validate() ) {
      submitSection( section );
    } else {
      setErrors( validationErrors );
      setErrorModal( true );
    }
    setSaveOrCompleteModal( false );
  }

  return (
    <Modal className="error-modal" size="tiny" open={ saveOrCompleteModal } closeOnDimmerClick={ false }>
      <Modal.Header>
        Section Complete?
      </Modal.Header>
      <Modal.Content>
        Would you like to mark this section as "Complete" or continue working on it?
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Save and Mark Complete"
          onClick={ handleComplete }
        />
        <Button
          content="Save and Continue"
          onClick={ handleSave }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default SaveOrCompleteModal;
