import React, { useContext } from 'react';
import { Form, Icon, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { Context } from '../../Context';
import { maskCurrencyInput, integerKeyDown, floatKeyDown } from '../../Services/inputMasks';
import DocumentUpload from '../Shared/DocumentUpload';

const MedicalPlanDetails = props => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    documents,
    mapDocuments,
    submissionInfo
  } = useContext( Context );

  // props
  const { refs } = props;

  return (
    <div className="medical-plan-details">
        <fieldset>
          <Form.Field
            width={ 8 }
            required
            error={ 'medicalRenewalDate' in errors }
          >
            <label>When is your medical plan renewal date?</label>
            <DatePicker
              disabled={ submissionInfo.benefits.isComplete }
              ref={ refs.medicalRenewalDateInput }
              isClearable
              selected={ formData.medicalRenewalDate ? new Date( formData.medicalRenewalDate ) : null }
              customInput={
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  keepCharPositions= {true}
                  guide = {true}
                />
              }
              onChange={ e => { updateField({ target: { name: "medicalRenewalDate", value: e } }) } }
              onBlur={ () => addError({ name: 'medicalRenewalDate' , error: validationErrors[ 'medicalRenewalDate' ] }) }
              onKeyDown={ e => {
                if ( e.key === "Tab" ) {
                  refs.medicalRenewalDateInput.current.setOpen( false );
                }
              }}
            />
          </Form.Field>
          <Form.Field
            width={ 8 }
            required
            error={ 'medicalPlanVendor'in errors }
          >
            <label>Who is your medical plan vendor?</label>
            <Input
              disabled={ submissionInfo.benefits.isComplete }
              ref={ refs.medicalPlanVendorInput }
              name="medicalPlanVendor"
              value={ formData.medicalPlanVendor || '' }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
            />
          </Form.Field>
          <Form.Field required>
            <label>Your medical plan is:</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="selfInsured"
              label="Self Insured"
              checked={ formData.selfInsured === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.benefits.isComplete }
              name="selfInsured"
              label="Fully Insured"
              checked={ formData.selfInsured === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          <Form.Field
            width={ 9 }
            required
            error={ 'plansOffered' in errors }
          >
            <label>How many types of medical plans are offered?</label>
            <Input
              disabled={ submissionInfo.benefits.isComplete }
              ref={ refs.plansOfferedInput }
              type="number"
              name="plansOffered"
              min={ 0 }
              value={ formData.plansOffered || '' }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              onKeyDown={ integerKeyDown }
            />
          </Form.Field>
          <Form.Field required>
            <label>What types of medical plan options are offered?</label>
            <Form.Checkbox
              disabled={ submissionInfo.benefits.isComplete }
              name="poS_Offered"
              label="POS"
              checked={ formData.poS_Offered || false }
              onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }) } }
              tabindex="0"
            />
            <Form.Checkbox
              disabled={ submissionInfo.benefits.isComplete }
              name="hmO_Offered"
              label="HMO"
              inline
              checked={ formData.hmO_Offered || false }
              onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }) } }
              tabindex="0"
            />
            <Form.Checkbox
              disabled={ submissionInfo.benefits.isComplete }
              name="ppO_Offered"
              label="Traditional PPO"
              inline
              checked={ formData.ppO_Offered || false }
              onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }) } }
              tabindex="0"
            />
            <Form.Checkbox
              disabled={ submissionInfo.benefits.isComplete }
              name="hdhP_Offered"
              label="High Deductible Health Plan (HDHP)"
              inline
              checked={ formData.hdhP_Offered || false }
              onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }) } }
              tabindex="0"
            />
            <Form.Checkbox
              disabled={ submissionInfo.benefits.isComplete }
              name="hrA_Offered"
              label="Health Reimbursement Arrangement (HRA)"
              inline
              checked={ formData.hrA_Offered || false }
              onChange={ ( e, { name, checked } ) => { updateField({ target: { name: name, value: checked } }) } }
              tabindex="0"
            />
          </Form.Field>
          { submissionInfo.benefits.isComplete
            ? <>
                <br />
                <Icon name="file alternate outline" />
                <span style={{ fontWeight: '700' }}>Medical Plan Summary:</span>
                &nbsp;
                { documents.medicalSummary ? mapDocuments( documents.medicalSummary, true ) : 'No document uploaded' }
                <br />
              </>
            : <>
                <br />
                <Form.Field>
                  <label>Please attach all medical plan summaries for each plan offered.</label>
                </Form.Field>
                <DocumentUpload typeID={ 38 } />
                { documents.medicalSummary ? mapDocuments( documents.medicalSummary ) : null }
              </>
          }
          { submissionInfo.benefits.isComplete
            ? <>
                <br />
                <Icon name="file alternate outline" />
                <span style={{ fontWeight: '700' }}>Medical Insurance Invoice:</span>
                &nbsp;
                { documents.medicalInvoice ? mapDocuments( documents.medicalInvoice, true ) : 'No document uploaded' }
              </>
            : <>
                <br />
                <Form.Field>
                  <label>Please attach your most recent medical insurance invoice.</label>
                </Form.Field>
                <DocumentUpload typeID={ 39 } />
                { documents.medicalInvoice ? mapDocuments( documents.medicalInvoice ) : null }
              </>
          }
        </fieldset>
        <br />
        <Form.Field required>
          <label>Does your plan include basic life?</label>
        </Form.Field>
        <Form.Group inline>
          <Form.Radio
            disabled={ submissionInfo.benefits.isComplete }
            name="basicLifeOffered"
            label="Yes"
            checked={ formData.basicLifeOffered === true }
            onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
            tabindex="0"
          />
          <Form.Radio
            disabled={ submissionInfo.benefits.isComplete }
            name="basicLifeOffered"
            label="No"
            checked={ formData.basicLifeOffered === false }
            onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
            tabindex="0"
          />
        </Form.Group>
        { formData.basicLifeOffered
            ? <Form.Field
                width={ 4 }
                required
                error={ 'basicLifeAmount' in errors }
              >
                <label>How much of basic life does your plan offer?</label>
                <Input
                  disabled={ submissionInfo.benefits.isComplete }
                  ref={ refs.basicLifeAmountInput }
                  name="basicLifeAmount"
                  icon="dollar sign"
                  iconPosition="left"
                  value={ formData.basicLifeAmount || '' }
                  onChange={ updateField }
                  onBlur={ e => {
                    updateField({ target: { name: e.target.name, value: maskCurrencyInput( e.target.value ) } });
                    addError({ name: e.target.name, error: validationErrors[ e.target.name ] })
                  }}
                  onKeyDown={ floatKeyDown }
                />
              </Form.Field>
            : null
        }
    </div>
  );
}

export default MedicalPlanDetails;
