import React from 'react';
import { Form, Table, Dropdown } from 'semantic-ui-react';
import { useForm } from '../../Hooks/useForm';
import { states } from '../../Services/options';
import { integerKeyDown, floatKeyDown } from '../../Services/inputMasks';
import RecordValidationPopup from '../Shared/RecordValidationPopup';

const WithholdingsTable = ( { addRecord, mapRecords, refs } ) => {

  const { formData, updateField, setFormData } = useForm({
    state: '',
    employeeCount: '',
    sutaNum: '',
    taxRate: '',
    whAccount: ''
  });

  const addButtonHandler = () => {
    addRecord( formData );
    setFormData({
      state: '',
      employeeCount: '',
      sutaNum: '',
      taxRate: '',
      whAccount: ''
    });
  }

  const disabled = (
    formData.state === ''
    || formData.employeeCount === ''
    // || formData.sutaNum === ''
    || formData.taxRate === ''
    // || formData.whAccount === ''
  );

  return (
    <div className="withholdings-table">
      <Form>
        <Table basic="very" textAlign="center">
          <Table.Body>
            <Table.Row style={{ marginBottom: '1rem' }}>
              <Table.Cell width={ 3 }>
              <Form.Field>
                  <label>State</label>
                  <Dropdown
                    ref={ refs.withholdingsStateInput }
                    name="state"
                    fluid
                    options={ states }
                    clearable
                    placeholder=""
                    selection
                    value={ formData.state }
                    onChange={ ( e, { value, name } ) => updateField({ target: { name, value } })  }
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  type="number"
                  name="employeeCount"
                  label="# of Employees"
                  min={ 0 }
                  fluid
                  value={ formData.employeeCount }
                  onChange={ updateField }
                  onKeyDown={ integerKeyDown }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  name="sutaNum"
                  label="SUTA Account #"
                  fluid
                  value={ formData.sutaNum }
                  onChange={ updateField }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  type="number"
                  name="taxRate"
                  label="Tax Rate"
                  min={ 0 }
                  step={ 0.01 }
                  icon="percent"
                  fluid
                  value={ formData.taxRate }
                  onChange={ updateField }
                  onBlur={ ( e ) => updateField({ target: { name: e.target.name, value: e.target.value } })}
                  onKeyDown={ floatKeyDown }
                />
              </Table.Cell>
              <Table.Cell width={ 3 }>
                <Form.Input
                  name="whAccount"
                  label="Withholding Account #"
                  fluid
                  value={ formData.whAccount }
                  onChange={ updateField }
                />
              </Table.Cell>
              <Table.Cell width={ 1 }>
                <RecordValidationPopup
                  recordType="withholding"
                  disabled={ disabled }
                  addButtonHandler={ addButtonHandler }
                />
              </Table.Cell>
            </Table.Row>
            { mapRecords() }
          </Table.Body>
        </Table>
      </Form>
    </div>
  );
}

export default WithholdingsTable;
