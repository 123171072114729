export const maskPhone = value => {
  if ( value ) {
    const arr = value.replace( /[^0-9]/g, '' ).split('');
    const maskedValue = arr.map( ( char, i ) => {
      switch ( i ) {
        case 0:
          return `(${ char }`;
        case 3:
          return `) ${ char }`;
        case 6:
          return `-${ char }`;
        default:
          return char;
      }
    });
    return maskedValue.join('');
  }
  return;
}

export const unmaskPhone = value => {
  if ( value ) {
    return value.replace( /[^0-9]/g, '' );
  }
  return;
}

export const validateEmail = value => {
  return /.+@.+\..+/.test( value );
}

export const maskFein = value => {
  if ( value ) {
    const arr = value.replace( /[^0-9]/g, '' ).split('');
    const maskedValue = arr.map( ( char, i ) => {
      switch ( i ) {
        case 2:
          return `-${ char }`;
        default:
          return char;
      }
    });
    return maskedValue.join('');
  }
  return;
}

export const unmaskFein = value => {
  if ( value ) {
    return value.replace( /[^0-9]/g, '' );
  }
  return;
}

export const maskCurrency = value => {
  if ( value === '' ) {
    return value;
  }
  const str = value.toString().replace( /[$,]/g, '' );
  const num = parseFloat( str );
  return num.toLocaleString( 'en-US', { style: 'currency', currency: 'USD' } );
};

export const maskCurrencyInput = value => {
  if ( value === '' ) {
    return value;
  }
  const str = value.toString().replace( /[$,]/g, '' );
  const num = parseFloat( str );
  return num.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } );
};

export const unmaskCurrency = value => {
  const str = value.toString().replace( /[$,]/g, '' );
  return parseFloat( str ).toFixed( 2 );
};

export const integerKeyDown = e => {
  if (
    e.key !== "Backspace"
    && e.key !== "Tab"
    && e.key !== "0"
    && e.key !== "1"
    && e.key !== "2"
    && e.key !== "3"
    && e.key !== "4"
    && e.key !== "5"
    && e.key !== "6"
    && e.key !== "7"
    && e.key !== "8"
    && e.key !== "9"
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
} // backspace, tab, 0-9, numpad 0-9

export const floatKeyDown =  e => {
  if (
    e.key !== "Backspace"
    && e.key !== "Tab"
    && e.key !== "0"
    && e.key !== "1"
    && e.key !== "2"
    && e.key !== "3"
    && e.key !== "4"
    && e.key !== "5"
    && e.key !== "6"
    && e.key !== "7"
    && e.key !== "8"
    && e.key !== "9"
    && e.key !== "."
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
} // backspace, tab, 0-9, numpad 0-9, period, numpad period(s)
