import React, { useContext } from 'react';
import { Dropdown, Form, Header, Input } from 'semantic-ui-react';
import { Context } from '../../Context';
import { states } from '../../Services/options';
import { maskPhone, unmaskPhone, integerKeyDown } from '../../Services/inputMasks';

const PrimaryContact = props => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    submissionInfo
  } = useContext( Context );

  // props
  const { refs } = props;

  return (
    <div className="primary-contact">
      <Header
        className="form-header"
        color="blue"
      >
        Primary Contact
      </Header>
      <Form>
        <fieldset>
          <Form.Group>
            <Form.Field
              width={ 8 }
              required
              error={ 'contactFirstName' in errors }
            >
              <label>First Name</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                ref={ refs.contactFirstNameInput }
                maxLength={ 125 }
                name="contactFirstName"
                value={ formData.contactFirstName || '' }
                onChange={ updateField }
                onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              />
            </Form.Field>
            <Form.Field
              width={ 8 }
              required
              error={ 'contactLastName' in errors }
            >
              <label>Last Name</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                ref={ refs.contactLastNameInput }
                maxLength={ 125 }
                name="contactLastName"
                value={ formData.contactLastName || '' }
                onChange={ updateField }
                onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field
            required
            error={ 'contactTitle' in errors }
          >
            <label>Title</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              ref={ refs.contactTitleInput }
              maxLength={ 50 }
              name="contactTitle"
              value={ formData.contactTitle || '' }
              onChange={ updateField }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
            />
          </Form.Field>
          <Form.Group>
            <Form.Field
              width={ 8 }
              required
              error={ 'contactEmail' in errors }
            >
              <label>Email</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                ref={ refs.contactEmailInput }
                maxLength={ 255 }
                name="contactEmail"
                value={ formData.contactEmail || '' }
                onChange={ updateField }
                onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              />
            </Form.Field>
            <Form.Field width={ 8 }>
              <label>Phone</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                maxLength={ 14 }
                name="contactPhone"
                value={ maskPhone( formData.contactPhone ) || '' }
                onChange={ ( e, { name, value } ) => updateField({ target: { name, value: unmaskPhone( value ) } }) }
                onKeyDown={ integerKeyDown }
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Address</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              maxLength={ 125 }
              name="contactAddress"
              value={ formData.contactAddress || '' }
              onChange={ updateField }
            />
          </Form.Field>
          <Form.Field>
            <label>Address2</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              maxLength={ 125 }
              name="contactAddress2"
              value={ formData.contactAddress2 || '' }
              onChange={ updateField }
            />
          </Form.Field>
          <Form.Group>
            <Form.Field width={ 8 }>
              <label>City</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                maxLength={ 125 }
                name="contactCity"
                value={ formData.contactCity || '' }
                onChange={ updateField }
              />
            </Form.Field>
            <Form.Field width={ 4 }>
              <label>State</label>
              <Dropdown
                disabled={ submissionInfo.general.isComplete }
                icon={ submissionInfo.general.isComplete ? null : 'dropdown' }
                name="contactState"
                fluid
                search
                selection
                clearable={ !submissionInfo.general.isComplete }
                placeholder="Please select a state"
                options={ states }
                value={ formData.contactState || '' }
                onChange={ (e, { name, value } ) => updateField({ target: { name, value } }) }
              />
            </Form.Field>
            <Form.Field width={ 4 }>
              <label>Zip</label>
              <Input
                disabled={ submissionInfo.general.isComplete }
                maxLength={ 5 }
                name="contactZip"
                value={ formData.contactZip || '' }
                onChange={ updateField }
                onKeyDown={ integerKeyDown }
              />
            </Form.Field>
          </Form.Group>
        </fieldset>
      </Form>
    </div>
  );
}

export default PrimaryContact;
