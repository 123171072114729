import React, { useContext, useEffect, useRef } from 'react';
import { Form, Input, Tab } from 'semantic-ui-react';
import { maskCurrencyInput, integerKeyDown, floatKeyDown } from '../../Services/inputMasks';
import { Context } from '../../Context';
import PayCycles from './PayCycles';
import Withholdings from './Withholdings';
import WorkersComp from './WorkersComp';
import SaveProgress from '../Shared/SaveProgress';
import EditSection from '../Shared/EditSection';

const Payroll = () => {

  // context
  const {
    formData,
    updateField,
    addError,
    errors,
    validationErrors,
    setValidationErrors,
    submissionInfo
  } = useContext( Context );

  // input refs
  let payRollVendorInput = useRef();
  let contractorsCountInput = useRef();
  let timeClockCountInput = useRef();
  let unemploymentClaimsInput = useRef();
  let cycleCountInput = useRef();
  let withholdingsStateInput = useRef();

  // validation errors
  useEffect( () => {
    setValidationErrors({
      payRollVendor: {
        order: 1,
        dependent: [
          [ 'usesService', false ]
        ],
        message: 'Payroll Vendor is a required field.',
        ref: payRollVendorInput
      },
      payCycles: {
        order: 2,
        type: 'dropdown',
        message: 'Please add at least one pay cycle to continue.',
        validate: 'length',
        ref: cycleCountInput
      },
      contractorsCount: {
        order: 3,
        dependent: [
          [ 'contractors', false ]
        ],
        message: 'Please enter the number of contractors your organization pays.',
        ref: contractorsCountInput
      },
      timeClockCount: {
        order: 4,
        dependent: [
          [ 'usesTimeClock', false ]
        ],
        message: 'Please enter the number of time clocks your organization uses for attendance.',
        ref: timeClockCountInput
      },
      unemploymentClaims: {
        order: 5,
        dependent: [
          [ 'isNFP', false ],
          [ 'isSubjectToFuta', true ]
        ],
        message: 'Please enter the amount in unemployment claims your organization has paid out in the last 12 months.',
        ref: unemploymentClaimsInput
      },
      withholdings: {
        order: 6,
        type: 'dropdown',
        message: 'Please add at least one State Unemployment & Withholding record to continue.',
        validate: 'length',
        ref: withholdingsStateInput
      },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] );

  useEffect( () => {
    if ( !formData.isNFP ) {
      updateField({ target: { name: 'isSubjectToFuta', value: false }})
      updateField({ target: { name: 'unemploymentClaims', value: maskCurrencyInput( 0 ) }})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.isNFP ]);

  useEffect( () => {
    if ( formData.isSubjectToFuta ) {
      updateField({ target: { name: 'unemploymentClaims', value: maskCurrencyInput( 0 ) }})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ formData.isSubjectToFuta ])

  return (
    <Tab.Pane className="payroll form-section">
      <div className="container">
        { !submissionInfo.accounting.isComplete
          ? <div className="section-actions">
              <SaveProgress />
            </div>
          : <EditSection sectionName={ 'Accounting Information' } />
        }
        <Form>
          <Form.Field required>
            <label>Does your organization use a payroll service or PEO?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              name="usesService"
              label="Yes"
              checked={ formData.usesService === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              label="No"
              name="usesService"
              checked={ formData.usesService === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          <br />
          { formData.usesService
            ? <>
                <Form.Field
                  width={ 4 }
                  required
                  error={ 'payRollVendor' in errors }
                >
                  <label>Who is your vendor?</label>
                  <Input
                    disabled={ submissionInfo.accounting.isComplete }
                    ref={ payRollVendorInput }
                    name="payRollVendor"
                    value={ formData.payRollVendor || '' }
                    onChange={ updateField }
                    onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                  />
                </Form.Field>
                <br />
              </>
            : null
          }
        </Form>
        <PayCycles refs={ { cycleCountInput } } />
        <br />
        <Form>
          <Form.Field required>
            <label>Does your organization pay 1099 independent contractors?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              name="contractors"
              label="Yes"
              checked={ formData.contractors === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              label="No"
              name="contractors"
              checked={ formData.contractors === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          <br />
          { formData.contractors
            ? <>
                <Form.Field
                  width={ 4 }
                  required
                  error={ 'contractorsCount' in errors }
                >
                  <label>How many contractors?</label>
                  <Input
                    disabled={ submissionInfo.accounting.isComplete }
                    ref={ contractorsCountInput }
                    type="number"
                    name="contractorsCount"
                    min={ 0 }
                    value={ formData.contractorsCount || '' }
                    onChange={ updateField }
                    onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                    onKeyDown={ integerKeyDown }
                  />
                </Form.Field>
                <br />
                <Form.Field required>
                  <label>Does your organization pay 1099 contractors through a payroll service?</label>
                </Form.Field>
                <Form.Group inline>
                  <Form.Radio
                    disabled={ submissionInfo.accounting.isComplete }
                    name="usesContractorService"
                    label="Yes"
                    checked={ formData.usesContractorService === true }
                    onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
                    tabindex="0"
                  />
                  <Form.Radio
                    disabled={ submissionInfo.accounting.isComplete }
                    label="No"
                    name="usesContractorService"
                    checked={ formData.usesContractorService === false }
                    onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
                    tabindex="0"
                  />
                </Form.Group>
                <br />
              </>
            : null
          }
          <Form.Field required>
            <label>Does your organization use automated time and attendance in the form of time clocks or web-based applications?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              name="usesTimeClock"
              label="Yes"
              checked={ formData.usesTimeClock === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              label="No"
              name="usesTimeClock"
              checked={ formData.usesTimeClock === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          <br />
          { formData.usesTimeClock
            ? <>
                <Form.Field
                  width={ 4 }
                  required
                  error={ 'timeClockCount' in errors }
                >
                  <label>How many time clocks?</label>
                  <Input
                    disabled={ submissionInfo.accounting.isComplete }
                    ref={ timeClockCountInput }
                    type="number"
                    name="timeClockCount"
                    min={ 0 }
                    value={ formData.timeClockCount || '' }
                    onChange={ updateField }
                    onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
                    onKeyDown={ integerKeyDown }
                  />
                </Form.Field>
                <br />
              </>
            : null
          }
          <Form.Field required>
            <label>Is your organization a Not for Profit?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              name="isNFP"
              label="Yes"
              checked={ formData.isNFP === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              label="No"
              name="isNFP"
              checked={ formData.isNFP === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
          { formData.isNFP
            ? <>
                <br />
                <Form.Field required>
                  <label>Is your organization subject to federal & State unemployment tax?</label>
                </Form.Field>
                <Form.Group inline>
                  <Form.Radio
                    disabled={ submissionInfo.accounting.isComplete }
                    name="isSubjectToFuta"
                    label="Yes"
                    checked={ formData.isSubjectToFuta === true }
                    onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
                    tabindex="0"
                  />
                  <Form.Radio
                    disabled={ submissionInfo.accounting.isComplete }
                    label="No"
                    name="isSubjectToFuta"
                    checked={ formData.isSubjectToFuta === false }
                    onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
                    tabindex="0"
                  />
                </Form.Group>
                { !formData.isSubjectToFuta
                  ? <>
                      <br />
                      <Form.Field
                        required
                        error={ 'unemploymentClaims' in errors }
                      >
                        <label>Please enter the amount in unemployment claims your organization has paid out in the last 12 months</label>
                      </Form.Field>
                      <Form.Field
                        width={ 4 }
                        required
                        error={ 'unemploymentClaims' in errors }
                      >
                        <Input
                          disabled={ submissionInfo.accounting.isComplete }
                          ref={ unemploymentClaimsInput }
                          name="unemploymentClaims"
                          value={ formData.unemploymentClaims || '' }
                          icon="dollar sign"
                          iconPosition="left"
                          onChange={ updateField }
                          onBlur={ e => {
                            updateField({ target: { name: e.target.name, value: maskCurrencyInput( e.target.value ) } });
                            addError({ name: e.target.name, error: validationErrors[ e.target.name ] });
                          }}
                          onKeyDown={ floatKeyDown }
                        />
                      </Form.Field>
                      <br />
                    </>
                  : null
                }
              </>
            : null
          }
        </Form>
        <br />
        <Withholdings  refs={ { withholdingsStateInput } } />
        <br />
        <Form>
          <Form.Field required>
            <label>Are you current with all Unemployment Tax obligations in the state of Indiana?</label>
          </Form.Field>
          <Form.Group inline>
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              name="isTaxCurrent"
              label="Yes"
              checked={ formData.isTaxCurrent === true }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
              tabindex="0"
            />
            <Form.Radio
              disabled={ submissionInfo.accounting.isComplete }
              label="No"
              name="isTaxCurrent"
              checked={ formData.isTaxCurrent === false }
              onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
              tabindex="0"
            />
          </Form.Group>
        </Form>
        <br />
        <WorkersComp />
      </div>
    </Tab.Pane>
  );
}

export default Payroll;
