import React, { useContext } from 'react';
import { Dropdown, Form, Input, TextArea } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { Context } from '../../Context';
import { companyTypes } from '../../Services/options';
import { maskFein, unmaskFein, integerKeyDown } from '../../Services/inputMasks';

const CompanyProfile = props => {

  // context
  const {
    formData,
    updateField,
    errors,
    addError,
    validationErrors,
    submissionInfo
  } = useContext( Context );

  const date = formData.dateOfCorp ? new Date( formData.dateOfCorp ) : null;

  // props
  const { refs } = props;

  return (
    <div className="company-profile">
      <br />
      <Form>
        <Form.Group>
          <Form.Field width={ 9 }>
            <label>Description of Business</label>
            <TextArea
              disabled={ submissionInfo.general.isComplete }
              name="description"
              value={ formData.description || '' }
              onChange={ updateField }
            />
          </Form.Field>
        </Form.Group>
        <br />
        <Form.Field required>
          <label>Is your organization domiciled in Indiana?</label>
        </Form.Field>
        <Form.Group inline>
          <Form.Radio
            disabled={ submissionInfo.general.isComplete }
            name="indianaDomiciled"
            label="Yes"
            checked={ formData.indianaDomiciled === true }
            onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
            tabindex="0"
          />
          <Form.Radio
            disabled={ submissionInfo.general.isComplete }
            label="No"
            name="indianaDomiciled"
            checked={ formData.indianaDomiciled === false }
            onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
            tabindex="0"
          />
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Field
            width={ 3 }
            required
            error={ 'fein' in errors }
          >
            <label>FEIN</label>
            <Input
              disabled={ submissionInfo.general.isComplete }
              ref={ refs.feinInput }
              maxLength={ 10 }
              name="fein"
              placeholder="ex. 12-3456789"
              value={ maskFein( formData.fein ) || '' }
              onChange={ ( e, { name, value } ) => updateField({ target: { name, value: unmaskFein( value ) } }) }
              onBlur={ e => addError({ name: e.target.name, error: validationErrors[ e.target.name ] }) }
              onKeyDown={ integerKeyDown }
            />
          </Form.Field>
          <Form.Field
            width={ 3 }
            required
            error={ 'typeOfCorp' in errors }
          >
            <label>Type of Corporation</label>
            <Dropdown
              disabled={ submissionInfo.general.isComplete }
              icon={ submissionInfo.general.isComplete ? null : 'dropdown' }
              ref={ refs.typeOfCorpInput }
              name="typeOfCorp"
              selection
              fluid
              search
              clearable={ !submissionInfo.general.isComplete }
              placeholder="Please select an option"
              options={ companyTypes }
              value={ formData.typeOfCorp || '' }
              onChange={ ( e, { name, value } ) => updateField({ target: { name, value } }) }
              onBlur={ ( e, { name } ) => addError({ name: name, error: validationErrors[ name ] }) }
            />
          </Form.Field>
          <Form.Field
            required
            error={ 'dateOfCorp' in errors }
          >
            <label>Date of Incorporation</label>
            <DatePicker
              disabled={ submissionInfo.general.isComplete }
              ref={ refs.dateOfCorpInput }
              isClearable
              selected={ date }
              tabIndex={ 0 }
              customInput={
                <MaskedInput
                  mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                  keepCharPositions= {true}
                  guide = {true}
                />
              }
              onChange={ e => updateField({ target: { name: "dateOfCorp", value: e } }) }
              onBlur={ () => addError({ name: 'dateOfCorp' , error: validationErrors[ 'dateOfCorp' ] }) }
              onKeyDown={ e => {
                if ( e.key === "Tab" ) {
                  refs.dateOfCorpInput.current.setOpen( false );
                }
              }}
            />
          </Form.Field>
        </Form.Group>
        { formData.typeOfCorp === 18 || formData.typeOfCorp === 29 || formData.typeOfCorp === 78
          ? <>
            <br />
              <Form.Field required>
                <label>Are your members or owners receiving W-2 wages?</label>
              </Form.Field>
              <Form.Group inline>
                <Form.Radio
                  disabled={ submissionInfo.general.isComplete }
                  name="w2wages"
                  label="Yes"
                  checked={ formData.w2wages === true }
                  onChange={ ( e, { name } ) => updateField({ target: { name, value: true } }) }
                  tabindex="0"
                />
                <Form.Radio
                  disabled={ submissionInfo.general.isComplete }
                  label="No"
                  name="w2wages"
                  checked={ formData.w2wages === false }
                  onChange={ ( e, { name } ) => updateField({ target: { name, value: false } }) }
                  tabindex="0"
                />
              </Form.Group>
              {/* <p style={{ color: '#990000' }}>Please Note: Partners who are not eligible to recieve W-2 wages are not eligible to participate in the WorkSmart benefit program.</p> */}
            </>
          : null
        }
      </Form>
      <br />
    </div>
  );
}

export default CompanyProfile
