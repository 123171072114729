import React, { useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { Context } from '../../Context';

const ErrorModal = () => {

  // context
  const { errors, errorModal, setErrorModal } = useContext( Context );

  // handlers
  const handleClose = () => {
    setErrorModal( false );
  }

  return (
    <Modal className="error-modal" size="mini" open={ errorModal } onClose={ handleClose }>
      <Modal.Header>
        { Object.keys( errors ).length } error(s) found in form entry!
      </Modal.Header>
      <Modal.Content>
        These errors must be corrected before this section may be marked complete.
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Close"
          onClick={ handleClose }
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ErrorModal;
