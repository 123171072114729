import React, { useContext } from 'react';
import { Form, Header, Icon, Table } from 'semantic-ui-react';
import { Context } from '../../Context';
import { maskCurrency } from '../../Services/inputMasks';
import PayCyclesTable from './PayCyclesTable';

const PayCycles = ( { refs } ) => {

  const { formData, updateField, submissionInfo } = useContext( Context );

  const addRecord = ( record ) => {
    updateField({
      target: {
        name: 'payCycles',
        value: [
          ...formData.payCycles,
          { ...record }
        ]
      }
    });
  }

  const removeRecord = ( index ) => {
    const updatedArray = formData.payCycles.filter( ( record, i ) => i !== index );
    updateField({
      target: {
        name: 'payCycles',
        value: updatedArray
      }
    })
  }

  const mapRecords = () => {
    if ( Object.keys( formData.payCycles ).length > 0 ) {
      return formData.payCycles.map( ( record, i ) => {
        return (
          <Table.Row key={ i }>
            <Table.Cell>{ record.cycleCount }</Table.Cell>
            <Table.Cell>{ record.ftCount }</Table.Cell>
            <Table.Cell>{ record.ptCount }</Table.Cell>
            <Table.Cell>{ maskCurrency( record.wages ) }</Table.Cell>
            <Table.Cell style={{ display: submissionInfo.accounting.isComplete ? 'none' : 'initial' }}>
              <Form.Button
                negative
                icon
                onClick={ () => removeRecord( i ) }
              >
                <Icon name="trash" />
              </Form.Button>
            </Table.Cell>
          </Table.Row>
        );
      });
    }

    return (
      <Table.Row key={ 0 } textAlign="left">
        <Table.Cell colSpan={ 5 }>
          No records.
        </Table.Cell>
      </Table.Row>
    );
  }

  return (
    <div className="pay-cycles">
      <Header
        className="form-header"
        color="blue"
      >
        Pay cycles
      </Header>
      <fieldset>
        { submissionInfo.accounting.isComplete
          ? <Table basic="very" textAlign="center">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Payroll Cycles Per Year</Table.HeaderCell>
                  <Table.HeaderCell># of Full Time Employees</Table.HeaderCell>
                  <Table.HeaderCell># of Part Time Employees</Table.HeaderCell>
                  <Table.HeaderCell>Gross Wages Per Month</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { mapRecords() }
              </Table.Body>
            </Table>
          : <>
              <legend>
                Please click the [ Save ] button to add a record
              </legend>
              <PayCyclesTable addRecord={ addRecord } mapRecords={ mapRecords } refs={ refs } />
            </>
        }
      </fieldset>
    </div>
  );
}

export default PayCycles;
