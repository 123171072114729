import React, { useContext } from 'react';
import { Icon, Step } from 'semantic-ui-react';
import { Context } from '../../Context';

const EditSection = props => {

  // context
  const {
    editSection,
    section,
    lockSection,
    submissionInfo
  } = useContext( Context );

  // props
  const { sectionName } = props;

  const handleClick = () => {
    editSection( section );
    lockSection( section );
  }

  const getSectionName = () => {
    switch( section ) {
      case 1:
        return 'general';
      case 2:
        return 'accounting';
      case 3:
        return 'benefits';
      case 4:
       return 'contacts';
      default:
        return;
    }
  }

  return (
    <div className="edit-section">
      <Step.Group style={{ border: 0 }}>
        <Step style={{ boxShadow: 'none' }}>
          <Step.Content>
            <Step.Title style={{ fontWeight: '300', lineHeight: 1.5, textAlign: 'center' }} >
              { sectionName } has been marked complete by:
              <br />
              <br />
              <span>{ submissionInfo[ getSectionName() ][ 'name' ] }</span>
              <br />
              <span>{ submissionInfo[ getSectionName() ][ 'email' ] }</span>
              <br />
              <br />
              <Icon name='pencil' /> Click <span style={{ color: '#2185d0', cursor: 'pointer' }} onClick={ handleClick }>here</span> to make changes.
            </Step.Title>
            <Step.Description></Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
}

export default EditSection;
