import React, { useContext } from 'react';
import { Form, Header } from 'semantic-ui-react';
import { validateEmail } from '../Services/validation';
import { Context } from '../Context';
import SignInPopup from './Shared/SignInPopup';

const SignInForm = props => {

  const {
    salesAgent,
    user,
    updateUser,
    signIn,
    setActiveIndex,
    checkLocks,
    lockSection
  } = useContext( Context );

  const signInDisabled = !( user.name && validateEmail( user.email ) );

  const handleSignIn = e => {
    e.preventDefault();
    signIn();
    const sectionIds = [ 1, 2, 3, 4 ];
    for ( let sectionId of sectionIds ) {
      // if locked check next section
      if ( checkLocks( sectionId ) ) continue;

      setActiveIndex( sectionId - 1 );
      lockSection( sectionId );
      break;
    }
  }

  return (
    <div className="sign-in-form">
    <Header
      className="form-header"
      color="blue"
    >
      Request for Proposal
    </Header>
      <p>
        Thank you for your interest in WorkSmart Systems! We know you have many options when choosing a vendor and we are happy to prepare a proposal for you. Please fill out the form below. If you need to leave this form and come back to it, please click the icon to your right at any time to save your progress, and use the link provided in your email to return to the form.
      </p>
      <p>
        If you have any questions, please contact:
      </p>
      <p>
        <strong>{ salesAgent.name }</strong>, <a href={ `mailto:${ salesAgent.email }` }>{ salesAgent.email }</a>, { salesAgent.phone }
      </p>
      <Header
        className="form-header"
        color="blue"
      >
        Welcome to the workSmart RFP submission system!
      </Header>
      <Form>
        <fieldset>
          <legend>Please provide your name and email address</legend>
          <Form.Input
            name="name"
            label="Name"
            value={ user.name || ''}
            onChange={ updateUser }
          />
          <Form.Input
            name="email"
            label="Email Address"
            value={ user.email || '' }
            onChange={ updateUser }
          />
        </fieldset>
        <br />
        <SignInPopup
          disabled={ signInDisabled }
          buttonHandler={ handleSignIn }
        />
      </Form>
    </div>
  );
}

export default SignInForm;
